import { Component } from "react"
import colors from "../../config/Colors.config"
import NavBar from "../../components/NavBar";
import { Button, Col, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { IconButton } from "@material-ui/core";
import components from "../../tools/components.tool";
import { Add, Cached, Delete, Search, Store } from "@material-ui/icons";
import Table from "react-data-table-component";
import unitModel from "../../models/unit.model";
import { ToastContainer, toast } from 'react-toastify';
import unidadModel from "../../models/unit.model";
import swal2 from 'sweetalert2';
import localityModel from "../../models/locality.model";

class CostCenter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModalUnidad: false,
            isDesktopDevice: null,
            referenciaBusquedaUnidad: "",
            isLoadTable: false,
            franquiciaList: [],
            estadosList: [],
            municipiosList: [],
            unidad: {
                IdUnidad: 0,
                IdEstado: 0,
                IdMunicipio: 0,
                IdFranquicia: 0,
                UDN: "",
                nombreUnidad: "",
            },
            columnUnidadList: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>UDN</p>,
                    selector: row => <label style={{ fontWeight: "bold" }} >{row.UDN}</label>,
                    sortable: true,
                    // width:"150px"
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre</p>,
                    selector: row => <label style={{ fontWeight: "bold" }} >{row.nombreUnidad}</label>,
                    sortable: true,
                    // width:"150px"
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Franquicia</p>,
                    selector: row => <label style={{ fontWeight: "bold" }} >{row.nombreFranquicia}</label>,
                    sortable: true,
                    // width:"150px"
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Estado</p>,
                    selector: row => <label style={{ fontWeight: "bold" }} >{row.nombreEstado}</label>,
                    sortable: true,
                    // width:"150px"
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Municipio</p>,
                    selector: row => <label style={{ fontWeight: "bold" }} >{row.nombreMunicipio}</label>,
                    sortable: true,
                    // width:"150px"
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Acciones</p>,
                    selector: row =>
                        <>
                            <IconButton
                                onClick={async () => {
                                    await this.eliminarUnidad(row);
                                }}
                            >
                                <Delete
                                    style={{
                                        color: colors.rojo
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={async () => {
                                    let response = await localityModel.getListMunicipioById(row.IdEstado);
                                    let municipiosList = response.municipios;
                                    await this.setState({
                                        isOpenModalUnidad: true,
                                        unidad: row,
                                        municipiosList
                                    });
                                }}
                            >
                                <Store
                                    style={{
                                        color: colors.azul
                                    }}
                                />
                            </IconButton>
                        </>,
                    sortable: true,
                    // width:"150px"
                },
            ],
            unidadList: []
        };
    }

    async componentDidMount() {
        document.body.style.backgroundColor = colors.azul;
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isDesktopDevice = !regexp.test(details);
        this.setState({
            isDesktopDevice
        });

        components.fucusElement("referenciaBusquedaUnidad");
        let response = await unidadModel.getFranquiciaList();
        let { franquiciaList } = response;

        response = await localityModel.getStateList();
        let estadosList = response.states;

        await this.setState({
            franquiciaList,
            estadosList
        });
        this.listarUnidades();

    }

    eliminarUnidad = async (unidad) => {

        swal2.fire({
            icon: 'question',
            title: '¿Eliminar unidad?',
            text: `¿Estas seguro de que quieres eliminar la unidad ${unidad.nombreUnidad}?`,
            //timer: "5000",
            confirmButtonColor: colors.azul,
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonText: 'Si',
            reverseButtons: true,
            allowEscapeKey: true,
            timerProgressBar: true,
            footer: `<p style="text-align: center; color: ${colors.rojo};">No se podrá recuperar los datos de la unidad una vez eliminado</p>`
        }).then(async (value) => {
            if (value.isConfirmed) {
                let response = await unidadModel.deleteUnidad(unidad);

                await this.listarUnidades();
                await this.setState({
                    isOpenModalUnidad: false
                });
                toast.success("Unidad eliminada");

            }
        });

    }

    buscarUnidad = async () =>{
        let referencia = await this.state.referenciaBusquedaUnidad;
        let response = await unitModel.searchUnidad(referencia);
        let {unidadList} = response;
        await this.setState({
            unidadList
        });

    }

    listarUnidades = async () => {
        await this.setState({
            isLoadTable: true
        });
        let response = await unitModel.getUnidadList();
        let { unidadList } = response;
        await components.sleep(1);
        toast.info(`Se listaron ${unidadList.length} unidades`);
        await this.setState({
            isLoadTable: false,
            unidadList
        });
    }

    saveUnidad = async () => {
        let response = await unidadModel.saveUnidad(this.state.unidad);
        
        await this.setState({
            unidad:{
                ...this.state.unidad,
                IdUnidad : response.IdUnidad
            }
        });
        this.listarUnidades();
        
        toast.success(response.message);
    }

    cambiarDatosUnidad = async (event) => {
        let { name, value } = event.target;

        await this.setState({
            unidad: {
                ...this.state.unidad,
                [name]: value,
            }
        });

    }

    render() {
        return (
            <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <NavBar isMobileDevice={!this.state.isDesktopDevice} />
                <div
                    style={{
                        fontFamily: "monospace",
                        marginTop: "100px",
                        backgroundColor: colors.blanco,
                        marginRight: "20px",
                        marginLeft: "20px",
                        borderRadius: "8px",
                        marginBottom: "50px",
                    }}
                >
                    <Row
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px",
                            marginTop: "100px",
                            backgroundColor: colors.blanco
                        }}
                    >
                        <Col
                            style={{
                                maxWidth: "40%",
                                width: "40%"
                            }}
                        >
                            <FormGroup
                                style={{
                                    marginTop: "10px"
                                }}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold"
                                    }}
                                >
                                    Buscar Unidad
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="referenciaBusquedaUnidad"
                                        name="referenciaBusquedaUnidad"
                                        type="text"
                                        focus
                                        placeholder="Nombre, UDN"
                                        style={{
                                            borderColor: "transparent",
                                            backgroundColor: "transparent",
                                            borderBottom: `solid 0.5px ${colors.azul}`,
                                            boxShadow: "none",
                                            // fontSize: "18px",
                                        }}
                                        value={this.state.referenciaBusquedaUnidad}
                                        onChange={async (event) => {
                                            await this.setState({
                                                referenciaBusquedaUnidad: event.target.value
                                            });
                                        }}
                                        onKeyDown={(event)=>{
                                            if(event.key == "Enter"){
                                                this.buscarUnidad();
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            style={{
                                textAlign: "right"
                            }}
                        >
                            <Button
                                title="Agregar Uniad"
                                style={{
                                    backgroundColor: colors.azul,
                                    height: "40px",
                                    width: "50px",
                                    marginTop: "30px",
                                    marginRight: "5px"
                                }}
                                onClick={() => {
                                    this.setState({
                                        isOpenModalUnidad: true,
                                        unidad: {
                                            IdUnidad: 0,
                                            IdEstado: 0,
                                            IdMunicipio: 0,
                                            IdFranquicia: 0,
                                            UDN: "",
                                            nombreUnidad: "",
                                        },
                                        municipiosList: [],
                                    });
                                }}
                                disabled={this.state.isLoadTable ? true : false}
                            >
                                {this.state.isLoadTable ?
                                    <Spinner
                                        style={{
                                            marginTop: "2px",
                                            height: "19px",
                                            width: "19px",
                                        }}
                                    />
                                    :
                                    <Add />
                                }

                            </Button>
                            <Button
                                title="Actualizar Tabla"
                                style={{
                                    backgroundColor: colors.azul,
                                    height: "40px",
                                    width: "50px",
                                    marginTop: "30px"
                                }}
                                onClick={async () => {
                                    await this.listarUnidades();
                                }}
                                disabled={this.state.isLoadTable ? true : false}
                            >
                                {this.state.isLoadTable ?
                                    <Spinner
                                        style={{
                                            marginTop: "2px",
                                            height: "19px",
                                            width: "19px",
                                        }}
                                    />
                                    :
                                    <Cached />
                                }

                            </Button>
                        </Col>
                    </Row>
                </div>
                <div
                    style={{
                        fontFamily: "monospace",
                        marginTop: "50px",
                        backgroundColor: colors.blanco,
                        marginRight: "20px",
                        marginLeft: "20px",
                        borderRadius: "8px",
                        marginBottom: "50px",
                    }}
                >
                    <div
                        style={{
                            marginRight: "20px",
                            marginLeft: "20px"
                        }}
                    >
                        <Row
                            style={{
                                marginBottom: "10px"
                            }}
                        >
                            <Col>
                                <Label
                                    style={{
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                        fontSize: "22px"
                                    }}
                                >
                                    Lista de Unidades ({this.state.isLoadTable ? "..." : this.state.unidadList.length})
                                </Label>

                            </Col>
                        </Row>
                        {
                            this.state.isLoadTable ?
                                <center>
                                    <lottie-player
                                        autoplay
                                        loop
                                        mode="normal"
                                        src="https://assets10.lottiefiles.com/packages/lf20_gbfwtkzw.json"

                                        style={{ width: "300px", height: "300px" }}
                                    ></lottie-player>
                                </center>
                                :
                                <Table
                                    columns={this.state.columnUnidadList}
                                    data={this.state.unidadList}
                                    noDataComponent={
                                        <label
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            tabla vacía
                                        </label>
                                    }
                                    pagination
                                />
                        }

                    </div>
                </div>
                <Modal
                    isOpen={this.state.isOpenModalUnidad}
                    size="lg"
                    onOpened={() => {
                        components.fucusElement("UDN");
                    }}
                >
                    <ModalHeader
                        toggle={() => {
                            this.setState({
                                isOpenModalUnidad: false,
                                unidad: {}
                            });
                        }}
                    >
                        {this.state.unidad.IdUnidad === 0 ? "Escribo los datos para la nueva unidad" : `Datos de la Unidad ${this.state.unidad.nombreUnidad}`}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold"
                                        }}
                                    >
                                        UND
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="UDN"
                                            name="UDN"
                                            type="text"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.unidad.UDN}
                                            onChange={(event) => {
                                                this.cambiarDatosUnidad(event)
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Nombre Unidad
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="nombreUnidad"
                                            name="nombreUnidad"
                                            type="text"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.unidad.nombreUnidad}
                                            onChange={(event) => {
                                                this.cambiarDatosUnidad(event)
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Franquicia
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="IdFranquicia"
                                            name="IdFranquicia"
                                            type="select"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.unidad.IdFranquicia}
                                            onChange={(event) => {
                                                this.cambiarDatosUnidad(event)
                                            }}
                                        >
                                            <option value={0}>Escoge una opción</option>
                                            {
                                                this.state.franquiciaList.map((franquicia) => {
                                                    return (
                                                        <option value={franquicia.IdFranquicia}>{franquicia.nombreFranquicia}</option>
                                                    );
                                                })
                                            }
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Estado
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="IdEstado"
                                            name="IdEstado"
                                            type="select"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.unidad.IdEstado}
                                            onChange={async (event) => {
                                                this.cambiarDatosUnidad(event);
                                                let response = await localityModel.getListMunicipioById(event.target.value);
                                                let municipiosList = response.municipios;
                                                await this.setState({
                                                    municipiosList,
                                                    unidad: {
                                                        ...this.state.unidad,
                                                        IdMunicipio: 0,
                                                    }
                                                });
                                            }}
                                        >
                                            <option value={0}>Escoge una opción</option>
                                            {
                                                this.state.estadosList.map((estado) => {
                                                    return (
                                                        <option value={estado.IdEstado}>{estado.nombreEstado}</option>
                                                    );
                                                })
                                            }
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Municipio
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="IdMunicipio"
                                            name="IdMunicipio"
                                            type="select"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.unidad.IdMunicipio}
                                            onChange={(event) => {
                                                this.cambiarDatosUnidad(event)
                                            }}
                                        >
                                            <option value={0}>Escoge una opción</option>
                                            {
                                                this.state.municipiosList.map((municipio) => {
                                                    return (
                                                        <option value={municipio.IdMunicipio}>{municipio.nombreMunicipio}</option>
                                                    );
                                                })
                                            }
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                width: "120px",
                                fontWeight: "bold"
                            }}
                            onClick={() => {
                                this.setState({
                                    isOpenModalUnidad: false,
                                    unidad: {
                                        IdUnidad: 0,
                                        IdEstado: 0,
                                        IdMunicipio: 0,
                                        IdFranquicia: 0,
                                        UDN: "",
                                        nombreUnidad: "",
                                    }
                                });
                            }}
                        >
                            Cerrar
                        </Button>

                        {
                            this.state.unidad.IdUnidad === 0 ?
                                null
                                :
                                <>
                                    <Button
                                        style={{
                                            width: "120px",
                                            backgroundColor: colors.rojo,
                                            fontWeight: "bold"
                                        }}
                                        onClick={async () => {
                                            await this.eliminarUnidad(this.state.unidad);

                                        }}
                                    >
                                        Eliminar
                                    </Button>
                                    <Button
                                        style={{
                                            width: "120px",
                                            backgroundColor: colors.azul,
                                            fontWeight: "bold"
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                unidad: {
                                                    IdUnidad: 0,
                                                    IdEstado: 0,
                                                    IdMunicipio: 0,
                                                    IdFranquicia: 0,
                                                    UDN: "",
                                                    nombreUnidad: "",
                                                },
                                                municipiosList: []
                                            })
                                        }}
                                    >
                                        Nuevo
                                    </Button>
                                </>
                        }
                        <Button
                            style={{
                                width: "120px",
                                backgroundColor: colors.azul,
                                fontWeight: "bold"
                            }}
                            onClick={() => {
                                this.saveUnidad();
                            }}
                        >
                            {this.state.unidad.IdUnidad === 0 ? "Guardar" : "Actualizar"}
                        </Button>

                    </ModalFooter>
                </Modal >
            </>
        );
    }

}

export default CostCenter;