import { Component } from "react";
import colors from "../../config/Colors.config";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import dominosLogo from "../../assets/images/Recurso_1.png";
import { Lock, LockOpen, Visibility, VisibilityOff } from "@material-ui/icons";
import components from "../../tools/components.tool";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import userModel from "../../models/user.model";

const cookies = new Cookies();


class NewUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobileDevice:null,
            isDesktopDevice:null,
            screenHeight:null,
            screenWidth:null,
            user: {
                username: "",
                password: "",
                confirmPassword: ""
            },
            isVisiblePassword: false
        }
    }

    async componentDidMount() {
        console.clear();
        // eslint-disable-next-line no-restricted-globals
        let screenHeight = screen.height.toString();
        // eslint-disable-next-line no-restricted-globals
        let screenWidth = screen.width.toString();
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);
        let isDesktopDevice = !isMobileDevice;

        this.setState({
            isMobileDevice,
            isDesktopDevice,
            screenHeight,
            screenWidth,
        });

        document.body.style.backgroundColor = colors.azul;
        components.fucusElement("password");

        let username = window.location.href.split('/')[4].replace("%20", " ")
        let user = await cookies.get("user", { path: "/" })
        
        await this.setState({
            user: {
                ...this.state.user,
                username,
            }
        });

    }


    cambiarDatosUsuario = async (event) => {
        let { name, value } = event.target;
        await this.setState({
            user: {
                ...this.state.user,
                [name]: value,
            }
        });
    }

    confirmarContraseñas = async () => {
        let { password, confirmPassword } = await this.state.user;
        let esValido = true;
        if (password.length === 0) {
            toast.warning("Escribe tu contraseña");
            components.fucusElement("password");
            esValido = false;
        } else {
            if (confirmPassword.length === 0) {
                toast.warning("Confirma tu contraseña");
                components.fucusElement("confirmPassword");
            } else {
                if (password !== confirmPassword) {
                    toast.error("Las contraseñas no coinciden");
                    components.fucusElement("password");
                    esValido = false;
                }
            }
        }

        return esValido;
    }

    guardarPassword = async () => {
        let esValido = await this.confirmarContraseñas();

        if(esValido){
           let response = await userModel.usuario_asignar_password(this.state.user.password);
            
            // cookies.set("user", user, { path: "/" })
            // cookies.set("token", user.token, { path: "/" })
            window.location.href = '/list'
        }
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div
                    style={{
                        backgroundColor: colors.blanco,
                        width: this.state.isDesktopDevice ? "33%" : "",
                        borderRadius: "8px",
                        marginRight: this.state.isDesktopDevice ? "33%" : "5px",
                        marginLeft: this.state.isDesktopDevice ? "33%" : "5px",
                        marginTop: "2%"
                    }}
                >
                    <Row>
                        <Col
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <img
                                src={dominosLogo}
                                alt="No se pudo cargar la imagen"
                                height={175}
                                style={{
                                    marginTop: "20px",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "20px"
                        }}
                    >
                        <Col>
                            <center>
                                <Label
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Ingresa la contraseña para el usuario {this.state.user.username}
                                </Label>
                            </center>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        <Col>
                            <FormGroup>
                                <Label
                                    style={{
                                        marginLeft: "5%",
                                        fontSize: "20px",
                                        color: "#000",
                                    }}
                                >
                                    Contraseña
                                </Label>
                                <InputGroup
                                    style={{
                                        width: "90%",
                                        marginRight: "5%",
                                        marginLeft: "5%",
                                        marginTop: "-10px",
                                    }}
                                >
                                    <InputGroupText
                                        style={{
                                            backgroundColor: "transparent",
                                            borderColor: "transparent",
                                            borderBottom: "solid 0.5px grey",
                                        }}
                                    >
                                        {this.state.isVisiblePassword ?
                                            <LockOpen style={{ color: colors.azul }} />
                                            :
                                            <Lock style={{ color: colors.azul }} />
                                        }
                                    </InputGroupText>
                                    <Input
                                        placeholder="Escribe tu contraseña"
                                        id="password"
                                        style={{
                                            borderColor: "transparent",
                                            backgroundColor: "transparent",
                                            borderBottom: "solid 0.5px grey",
                                            boxShadow: "none",
                                            fontSize: "18px",
                                        }}
                                        value={this.state.user.password}
                                        name="password"
                                        type={this.state.isVisiblePassword ? "text" : "password"}
                                        onChange={(event) => {
                                            this.cambiarDatosUsuario(event);
                                        }}
                                        onKeyDown={(event) => {
                                            let { key } = event;

                                            if (key === "Enter") {
                                                components.fucusElement("confirmPassword");
                                            }
                                        }}
                                    />
                                    <InputGroupText
                                        onClick={() => {
                                            this.setState({
                                                isVisiblePassword: !this.state.isVisiblePassword,
                                            });
                                        }}
                                        style={{
                                            backgroundColor: "transparent",
                                            borderColor: "transparent",
                                            borderBottom: "solid 0.5px grey",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {
                                            this.state.isVisiblePassword ?
                                                <Visibility style={{ color: colors.azul }} />
                                                :
                                                <VisibilityOff style={{ color: colors.azul }} />
                                        }
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        <Col>
                            <FormGroup>
                                <Label
                                    style={{
                                        marginLeft: "5%",
                                        fontSize: "20px",
                                        color: "#000",
                                    }}
                                >
                                    Confirme su contraseña
                                </Label>
                                <InputGroup
                                    style={{
                                        width: "90%",
                                        marginRight: "5%",
                                        marginLeft: "5%",
                                        marginTop: "-10px",
                                    }}
                                >
                                    <InputGroupText
                                        style={{
                                            backgroundColor: "transparent",
                                            borderColor: "transparent",
                                            borderBottom: "solid 0.5px grey",
                                        }}
                                    >

                                        <Lock style={{ color: colors.azul }} />

                                    </InputGroupText>
                                    <Input
                                        placeholder="**********"
                                        id="confirmPassword"
                                        style={{
                                            borderColor: "transparent",
                                            backgroundColor: "transparent",
                                            borderBottom: "solid 0.5px grey",
                                            boxShadow: "none",
                                            fontSize: "18px",
                                        }}
                                        value={this.state.user.confirmPassword}
                                        name="confirmPassword"
                                        type="password"
                                        onChange={(event) => {
                                            this.cambiarDatosUsuario(event);
                                        }}
                                        onKeyDown={(event) => {
                                            let { key } = event;

                                            if (key === "Enter") {
                                                // components.fucusElement("password");
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "20px",

                        }}
                    >
                        <Col
                            style={{
                                textAlign: "left",
                            }}
                        >
                            <Button
                                style={{
                                    marginLeft: "10%",
                                    width: "90%",
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    boxShadow: "none",
                                    backgroundColor: colors.blanco,
                                    borderColor: colors.azul,
                                    color: colors.azul,
                                }}
                                onClick={() => {
                                    this.setState({
                                        user: {
                                            password: "",
                                            confirmPassword: ""
                                        },
                                        textInfoValidation: ""
                                    });
                                    components.fucusElement("password");
                                }}
                            >
                                <label>Cancelar</label>
                            </Button>
                        </Col>
                        <Col
                            style={{
                                textAlign: "right",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    this.guardarPassword();
                                }}
                                style={{
                                    fontSize: "18px",
                                    width: "90%",
                                    marginRight: "10%",
                                    fontWeight: "bold",
                                    boxShadow: "none",
                                    backgroundColor: colors.azul,
                                    borderColor: colors.azul,
                                    color: colors.blanco,
                                }}
                            >
                                <label>Continuar</label>
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "30px" }} ><Col></Col></Row>
                </div>
            </>
        );
    }

}

export default NewUser;