import { Component } from "react";
import "../../styles/NotFound404.css"

class NotFound404 extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <main>
                <div className="pannel left">
                    <div className="half top">
                        <a href="/">Pagina principal</a>
                    </div>
                    <div className="half bottom">
                        <a href="/login">Ir al Login</a>
                    </div>
                </div>
                <div className="pannel right">
                    <p className="p404">404</p>
                    <p className="text">Pagina no encontrada</p>
                </div>
            </main>
        );
    }

}


export default NotFound404;

