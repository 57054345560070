import { Component } from "react"
import colors from "../../config/Colors.config"

import userModel from "../../models/user.model";

import NavBar from "../../components/NavBar";
import swal2 from 'sweetalert2';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import {
    Search,
    Cached,
    Edit,
    Add,
    Delete,
    Visibility,
    VisibilityOff,
} from '@material-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import {
    Row,
    FormGroup,
    Label,
    InputGroupText,
    Input,
    Col,
    InputGroup,
    Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Spinner,
    FormFeedback,
    FormText,
} from "reactstrap";
import Table from "react-data-table-component";
import IconButton from "@material-ui/core/IconButton";
import components from "../../tools/components.tool";

class ApplicantList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusTable: 0,
            isLoadTable: false,
            textInfo: "",
            isVisiblePassword: false,
            user: {
                IdUsuario: 0,
                username: "",
                nombreCompleto: "",
                IdFranquiciasAsociada: -1,
                IdTipoUsuario: 0,
            },
            descripocionTipoUsuario: {
                0: "",
                1: "Acceso a la configuración del sistema",
                2: "Solo puede ver información del sistema",
            },
            isOpenModaluser: false,
            isChrome: false,
            userList: [],
            textBuscar: "",
            columsUserList: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre Completo</p>,
                    selector: row => <label style={{ fontWeight: "bold" }} >{row.nombreCompleto}</label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre de Usuario</p>,
                    selector: row =>
                        <label style={{ fontWeight: "bold" }} >
                            {row.username}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Tipo de Usuario</p>,
                    selector: row =>
                        <label style={{ fontWeight: "bold" }} >
                            {row.tipoUsuario}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Franquicia Asociada</p>,
                    selector: row =>
                        <label style={{ fontWeight: "bold" }} >
                            {row.franquiciaAsociada}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Acciones</p>,
                    selector: row =>
                        <>
                            <IconButton
                                onClick={async () => {
                                    swal2.fire({
                                        icon: 'question',
                                        title: '¿Eliminar usuario?',
                                        text: `¿Estas seguro de que quieres eliminar al usaurio ${row.username}?`,
                                        //timer: "5000",
                                        confirmButtonColor: colors.azul,
                                        showCancelButton: true,
                                        cancelButtonText: "No",
                                        confirmButtonText: 'Si',
                                        reverseButtons: true,
                                        allowEscapeKey: true,
                                        timerProgressBar: true,
                                        footer: `<p style="text-align: center; color: ${colors.rojo};">No se podra recuperar los datos del usuario una vez eliminado</p>`
                                    }).then(async (value) => {
                                        if (value.isConfirmed) {
                                            await userModel.userDelete(row.IdUsuario);
                                            await this.getUserList(1);
                                        }
                                    });

                                }}
                            >
                                <Delete
                                    style={{
                                        color: colors.rojo,
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        user: {
                                            IdUsuario: row.IdUsuario,
                                            username: row.username,
                                            nombreCompleto: row.nombreCompleto,
                                            IdFranquiciasAsociada: row.IdFranquiciasAsociada,
                                            IdTipoUsuario: row.IdTipoUsuario,
                                        },
                                        isOpenModaluser: true,
                                    });
                                }}
                            >
                                <Edit
                                    style={{
                                        color: colors.azul,
                                    }}
                                />
                            </IconButton>
                        </>,
                    sortable: true
                },
            ]
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = colors.azul;
        // document.body.style.backgroundImage = "linear-gradient(to bottom, #006492, #016898, #026d9e, #0371a5, #0476ab, #047ab1, #057fb8, #0583be, #0689c6, #078ece, #0794d6, #089ade)";
        /*
        background-image: linear-gradient(to bottom, #006492, #016898, #026d9e, #0371a5, #0476ab, #047ab1, #057fb8, #0583be, #0689c6, #078ece, #0794d6, #089ade);
        */

        let isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

        this.setState({
            isChrome
        });

        components.fucusElement("textBuscar");

        this.getUserList(0);


    }

    getUserList = async (origen) => {

        await this.setState({
            isLoadtable: true,
            userList: [],
            statusTable: 0
        });
        await components.sleep(1);
        let response;
        if (origen === 1) {
            response = await userModel.getUSerList("");

        } else {
            response = await userModel.getUSerList(this.state.textBuscar);

        }


        let { userList } = response;

        await this.setState({
            userList,
            isLoadtable: false
        });
        let { length } = userList
        if (origen === 0) {
            if (length === 0) {
                toast.warning("No hay usuarios registrados");
                await this.setState({
                    statusTable: 1
                });
            } else {
                toast.info(`Se encontraron ${length} usuarios`);
            }

        }
        if (origen === 1) {
            toast.success(`Tabla actualizada`);
            if (length === 0) {

                toast.warning("No hay usuarios registrados");
                await this.setState({
                    statusTable: 1
                });
            } else {
                toast.info(`Se encontraron ${length} usuarios`);
            }

        }
    }

    handlechangeUser = async (event) => {
        let { name, value } = event.target;
        
        if (value.length > 45) {
            return;
        }
        await this.setState({
            user: {
                ...this.state.user,
                [name]: value
            },
        });
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <NavBar isMobileDevice={false} />

                <div
                    style={{
                        fontFamily: "monospace",
                        marginTop: "100px",
                        backgroundColor: colors.blanco,
                        marginRight: "20px",
                        marginLeft: "20px",
                        borderRadius: "8px",
                        marginBottom: "50px",
                    }}
                >
                    <Row
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px",
                        }}
                    >
                        <Col
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        // fontSize: "22px",

                                    }}
                                >
                                    Buscar usuario
                                </Label>
                                <InputGroup>
                                    <Input
                                        focus
                                        id="textBuscar"
                                        name="textBuscar"
                                        style={{
                                            borderColor: "transparent",
                                            backgroundColor: "transparent",
                                            borderBottom: `solid 0.5px ${colors.azul}`,
                                            boxShadow: "none",
                                            fontSize: "18px",
                                            marginTop: "-5px"
                                        }}
                                        value={this.state.textBuscar}
                                        type="text"
                                        onChange={(event) => {
                                            let { value } = event.target;
                                            this.setState({
                                                textBuscar: value
                                            });
                                        }}
                                        onKeyDown={(event) => {
                                            let { key } = event;
                                            if (key === "Enter") {
                                                this.getUserList(0);
                                            }
                                        }}
                                    />
                                    <InputGroupText
                                        style={{
                                            backgroundColor: "transparent",
                                            borderColor: "transparent",
                                            borderBottom: "solid 0.5px grey",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            this.getUserList(0);
                                        }}
                                    >
                                        <Search
                                            style={{
                                                color: colors.azul
                                            }}
                                        />
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                textAlign: "right",
                            }}
                        >
                            <Button
                                title="Actualizar tabla"
                                style={{
                                    backgroundColor: colors.azul,
                                    marginTop: "35px",
                                    marginRight: "15px",
                                    height: "40px",
                                    width: "50px",
                                }}
                                disabled={this.state.isLoadTable}
                                onClick={() => {
                                    this.setState({
                                        isOpenModaluser: true,
                                        user: {
                                            IdUsuario: 0,
                                            username: "",
                                            password: "",
                                            nombreCompleto: "",
                                            correo: "",
                                        },
                                    })
                                }}
                            >
                                <Add />
                            </Button>
                            <Button
                                title="Actualizar tabla"
                                style={{
                                    marginTop: "35px",
                                    height: "40px",
                                    width: "50px",
                                    backgroundColor: colors.azul
                                }}
                                onClick={() => {
                                    this.getUserList(1);
                                }}
                            >
                                {this.state.isLoadTable ?
                                    <Spinner
                                        style={{
                                            marginTop: "2px",
                                            height: "19px",
                                            width: "19px",
                                        }}
                                    />
                                    :
                                    <Cached />
                                }

                            </Button>
                        </Col>
                    </Row>
                </div>
                <div
                    style={{
                        fontFamily: "monospace",
                        marginTop: "50px",
                        backgroundColor: colors.blanco,
                        marginRight: "20px",
                        marginLeft: "20px",
                        borderRadius: "8px",
                        marginBottom: "50px"
                    }}
                >
                    <div
                        style={{
                            marginRight: "20px",
                            marginLeft: "20px"
                        }}
                    >
                        <Row
                            style={{
                                marginBottom: "10px"
                            }}
                        >
                            <Col>
                                <Label
                                    style={{
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                        fontSize: "22px"
                                    }}
                                >
                                    Lista de usuarios
                                </Label>

                            </Col>
                        </Row>
                        {this.state.userList.length === 0 ?
                            <center>
                                {
                                    this.state.statusTable === 0 ?


                                        <lottie-player
                                            autoplay
                                            loop
                                            mode="normal"
                                            src="https://assets10.lottiefiles.com/packages/lf20_gbfwtkzw.json"

                                            style={{ width: "300px", height: "300px" }}
                                        ></lottie-player>
                                        :

                                        <Label>Tabla vacia</Label>
                                }
                            </center>
                            :
                            <Table
                                columns={this.state.columsUserList}
                                data={this.state.userList}
                                noDataComponent={
                                    <label
                                        style={{
                                            fontWeight: "bold"
                                        }}
                                    >
                                        tabla vacia
                                    </label>
                                }
                                pagination
                            />
                        }


                    </div>

                </div>
                <Modal
                    isOpen={this.state.isOpenModaluser}
                    onOpened={() => {
                        components.fucusElement("nombreCompleto");
                    }}
                >

                    <ModalHeader
                        toggle={() => {
                            this.setState({
                                isOpenModaluser: false
                            });
                        }}
                    >
                        Datos del Usuario
                    </ModalHeader>

                    <ModalBody>
                        {
                            this.state.textInfo.length === 0 ?
                                null
                                :
                                <Row>
                                    <Col
                                        style={{
                                            textAlign: "center"
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: "orange",
                                                fontSize: "18px"
                                            }}
                                        >
                                            {this.state.textInfo}
                                        </Label>
                                    </Col>
                                </Row>
                        }

                        <Row>
                            <Col
                                style={{
                                    marginTop: "10px",
                                }}
                            >
                                <FormGroup>
                                    <Label>
                                        Nombre completo
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="nombreCompleto"
                                            name="nombreCompleto"
                                            type="text"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.user.nombreCompleto}
                                            onChange={(event) => {
                                                this.handlechangeUser(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                style={{
                                    marginTop: "10px",
                                }}
                            >
                                <FormGroup>
                                    <Label>
                                        Nombre de Usuario
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="username"
                                            name="username"
                                            type="text"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.user.username}
                                            onChange={(event) => {
                                                this.handlechangeUser(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                style={{
                                    marginTop: "10px",
                                }}
                            >
                                <FormGroup>
                                    <Label>
                                        Tipo de Usuario
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="IdTipoUsuario"
                                            name="IdTipoUsuario"
                                            type="select"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.user.IdTipoUsuario}
                                            onChange={(event) => {
                                                this.handlechangeUser(event);
                                            }}
                                        >
                                            <option value={0}>Elige una opción</option>
                                            <option value={1}>Administrador</option>
                                            <option value={2}>Lector</option>
                                        </Input>
                                    </InputGroup>
                                    <FormText>
                                        {this.state.descripocionTipoUsuario[this.state.user.IdTipoUsuario]}
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                style={{
                                    marginTop: "10px",
                                }}
                            >
                                <FormGroup>
                                    <Label>
                                        Franquicias Asociada
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="IdFranquiciasAsociada"
                                            name="IdFranquiciasAsociada"
                                            type="select"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.user.IdFranquiciasAsociada}
                                            onChange={(event) => {
                                                this.handlechangeUser(event);
                                            }}
                                        >
                                            <option value={-1}>Elige una opción</option>
                                            <option value={0}>Todas las Franquicias</option>
                                            <option value={1}>Franquicia A</option>
                                            <option value={2}>Franquicia B</option>
                                            <option value={3}>Franquicia C</option>
                                            <option value={4}>Franquicia D</option>
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: "grey",
                                width: "150px"
                            }}
                            onClick={() => {
                                this.setState({
                                    isOpenModaluser: false,
                                })
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                width: "150px"
                            }}
                            onClick={async () => {
                                await this.setState({
                                    isLoadtable: true,
                                });
                                if (this.state.user.IdUsuario === 0) {
                                    
                                    if (
                                        this.state.user.username.length === 0 ||
                                        this.state.user.nombreCompleto.length === 0 ||
                                        this.state.user.IdTipoUsuario == "0" ||
                                        this.state.user.IdFranquiciasAsociada == "-1"
                                    ) {
                                        toast.warning("Faltan datos por llenar");
                                        // this.setState({
                                        //     textInfo: "Debes de llenar el nombre de usuario y la contraseña como minimo"
                                        // })
                                    } else {

                                        let response = await userModel.userSave(this.state.user);
                                        let { status } = response;
                                        if (status === 200) {
                                            await this.getUserList(1);
                                            toast.success(`Usuario ${this.state.user.IdUsuario === 0 ? "guardado" : "actualizado"}`);
                                            await this.setState({
                                                isOpenModaluser: false,
                                            })
                                        }
                                    }
                                } else {

                                    let response = await userModel.userSave(this.state.user);
                                    let { status } = response;
                                    if (status === 200) {
                                        await this.getUserList(1);
                                        toast.success(`Usuario ${this.state.user.IdUsuario === 0 ? "guardado" : "actualizado"}`);

                                    }
                                }






                            }}
                        >
                            {
                                this.state.isLoadTable ?
                                    <Spinner
                                        style={{
                                            marginTop: "2px",
                                            height: "19px",
                                            width: "19px",
                                        }}
                                    />
                                    :
                                    this.state.user.IdUsuario === 0 ? "Guardar" : "Actualizar"

                            }

                        </Button>
                    </ModalFooter>

                </Modal>

            </>
        );
    }
}


export default ApplicantList