import React, { useEffect, useState } from 'react';
import {
    Navbar,
    Nav,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row,
    Col,
} from 'reactstrap';

import colors from "../config/Colors.config";
import {
    Menu,
    AccountCircle,
    Person,
    FormatAlignJustify,
    ExitToApp,
} from '@material-ui/icons';


import Cookies from 'universal-cookie';
const cookies = new Cookies();


const NavBarMovil = (props) => {
    const [tipoUsuario, setTipoUsuario] = useState(0)

    const [isOpen, setIsOpen] = useState(false);
    const [style] = useState({
        backgroundColor: "#fff",
        color: '#016390',
        fontWeight: "bold",
        border: "none",
        boxShadow: "none"
    });
    const [styleIcon] = useState({
        marginTop: "-5px",
        marginRight: "5px",
        color: "#016390"
    });
    const [styleIconSecundari] = useState({
        marginTop: "-5px",
        marginRight: "5px",
        color: colors.azul
    });

    const open = () => setIsOpen(!isOpen);


    const logOut = () => {
        cookies.remove('user', { path: "/" });
        window.location.href = "/login";
    }


    useEffect(() => {
        let tipoUsuario = cookies.get("user", { path: "/" }).IdTipoUsuario;

        setTipoUsuario(tipoUsuario);
    }, []);

    return (

        <div

        >
            <Navbar expand="md" style={{
                backgroundColor: "#fff",
                height: "62px",
                width: "100%"
            }}>
                <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                </Nav>
                <Button
                    //color="primary"
                    style={{ backgroundColor: "#fff", borderColor: "#fff", boxShadow: "none", color: "#016390", fontWeight: "bold" }}
                    onClick={open}
                >
                    <Menu style={{ color: "#016390" }} />
                </Button>
                <Offcanvas
                    toggle={open}
                    isOpen={isOpen}
                    direction="end"
                    style={{
                        backgroundColor: "#fff"
                    }}
                >
                    <OffcanvasHeader toggle={open}>
                        <h2
                            style={{
                                color: "#016390",
                                fontWeight: "bold"
                            }}
                        >
                            Menu
                        </h2>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Nav vertical>

                            {
                                tipoUsuario === 1 ?
                                    <Row>
                                        <Col
                                            style={{
                                                textAlign: "left"
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    backgroundColor: colors.blanco,
                                                    color: colors.azul,
                                                    boxShadow: "none",
                                                    fontWeight: "bold",
                                                    borderColor: colors.blanco,
                                                }}
                                                onClick={() => {
                                                    window.location.href = "/user-list";
                                                }}
                                            >
                                                <AccountCircle style={styleIconSecundari} />
                                                Usuarios
                                            </Button>
                                        </Col>
                                    </Row>
                                    :
                                    null
                            }

                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: colors.blanco,
                                            color: colors.azul,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderColor: colors.blanco,
                                        }}
                                        onClick={() => {
                                            window.location.href = "/list";
                                        }}
                                    >
                                        <Person style={styleIconSecundari} />
                                        Postulantes
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: colors.blanco,
                                            color: colors.azul,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderColor: colors.blanco,
                                        }}
                                        onClick={() => {
                                            // window.location.href = "/";
                                            window.open("/", '_blank')
                                        }}
                                    >
                                        <FormatAlignJustify style={styleIconSecundari} />
                                        Formulario
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: colors.blanco,
                                            color: colors.azul,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderColor: colors.blanco,
                                        }}
                                        onClick={() => logOut()}
                                    >
                                        <ExitToApp style={styleIconSecundari} />
                                        Cerrar Sesion
                                    </Button>
                                </Col>
                            </Row>



                        </Nav>

                    </OffcanvasBody>

                </Offcanvas>
            </Navbar>
        </div >


    );
}

export default NavBarMovil;