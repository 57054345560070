import { Component } from "react"
import colors from "../../config/Colors.config"
import postulanteModel from "../../models/postulante.model";
import userModel from "../../models/user.model";
import DataTimeTool from "../../tools/DataTime.tools";
import NavBar from "../../components/NavBar";
import swal2 from 'sweetalert2';
import {
    Search,
    Cached,
    Edit,
    Add,
    Delete,
    Visibility,
    VisibilityOff,
} from '@material-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import {
    Row,
    FormGroup,
    Label,
    InputGroupText,
    Input,
    Col,
    InputGroup,
    Button,
    Progress,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Spinner,
} from "reactstrap";
import Table from "react-data-table-component";
import IconButton from "@material-ui/core/IconButton";
import components from "../../tools/components.tool";

class ApplicantList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textInfo:"",
            isVisiblePassword: false,
            invalidDataUser: {
                username: "",
                password: "",
            },
            user: {
                IdUsuario: 0,
                username: "",
                password: "",
                nombreCompleto: "",
                correo: "",
            },
            isOpenModaluser: false,
            isChrome: false,
            userList: [],
            textBuscar: "all",
            columsUserList: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre Completo</p>,
                    selector: row => <label style={{ fontWeight: "bold" }} >{row.nombreCompleto}</label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre de Usuario</p>,
                    selector: row =>
                        <label style={{ fontWeight: "bold" }} >
                            {row.username}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Correo</p>,
                    selector: row =>
                        <label style={{ fontWeight: "bold" }} >
                            {row.correo}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Correo</p>,
                    selector: row =>
                        <>
                            <IconButton
                                onClick={async () => {
                                    swal2.fire({
                                        icon: 'question',
                                        title: '¿Eliminar usuario?',
                                        text: `¿Estas seguro de que quieres eliminar al usaurio ${row.username}?`,
                                        //timer: "5000",
                                        confirmButtonColor: colors.azul,
                                        showCancelButton: true,
                                        cancelButtonText: "No",
                                        confirmButtonText: 'Si',
                                        reverseButtons: true,
                                        allowEscapeKey: true,
                                        timerProgressBar: true,
                                        footer: `<p style="text-align: center; color: ${colors.rojo};">No se podra recuperar los datos del usuario una vez eliminado</p>`
                                    }).then(async (value) => {
                                        if (value.isConfirmed) {
                                            await userModel.userDelete(row.IdUsuario);
                                            await this.getUserList(1);
                                        }
                                    });

                                }}
                            >
                                <Delete
                                    style={{
                                        color: colors.rojo,
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        user: row,
                                        isOpenModaluser: true,
                                    });
                                }}
                            >
                                <Edit
                                    style={{
                                        color: colors.azul,
                                    }}
                                />
                            </IconButton>
                        </>,
                    sortable: true
                },
            ]
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = colors.azul;
        // document.body.style.backgroundImage = "linear-gradient(to bottom, #006492, #016898, #026d9e, #0371a5, #0476ab, #047ab1, #057fb8, #0583be, #0689c6, #078ece, #0794d6, #089ade)";
        /*
        background-image: linear-gradient(to bottom, #006492, #016898, #026d9e, #0371a5, #0476ab, #047ab1, #057fb8, #0583be, #0689c6, #078ece, #0794d6, #089ade);
        */

        let isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

        this.setState({
            isChrome
        });

        components.fucusElement("textBuscar");

        this.getUserList(0);


    }

    getUserList = async (origen) => {
        let response = await userModel.getUSerList(this.state.textBuscar);
        await this.setState({
            textBuscar: this.state.textBuscar == "all" ? "" : this.state.textBuscar,
        });

        let { userList } = response;
        await this.setState({
            userList
        });
        let { length } = userList
        if (origen === 0) {
            if (length === 0) {
                toast.warning("No hay usuarios registrados");
            } else {
                toast.info(`Se encontraron ${length} usuarios`);
            }

        }
        if (origen === 1) {
            toast.success(`Tabla actualizada`);
            if (length === 0) {

                toast.warning("No hay usuarios registrados");
            } else {
                toast.info(`Se encontraron ${length} usuarios`);
            }

        }
    }

    handlechangeUser = async (event) => {
        let { name, value } = event.target;
        
        if (value.length > 45) {
            return;
        }
        await this.setState({
            user: {
                ...this.state.user,
                [name]: value
            },
            invalidDataUser: {
                ...this.state.invalidDataUser,
                [name]: value.length === 0 ? true : false
            }
        });
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <NavBar isMobileDevice={true} />
                <div
                    style={{
                        fontFamily: "monospace",
                        marginTop: "20px",
                        backgroundColor: colors.blanco,
                        marginRight: "10px",
                        marginLeft: "10px",
                        borderRadius: "8px",
                        marginBottom: "50px",
                    }}
                >
                    <Row
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px",
                        }}
                    >
                        <Col
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px"
                            }}
                            md="4"
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "22px",

                                    }}
                                >
                                    Buscar usuario
                                </Label>
                                <InputGroup>
                                    <Input
                                        focus
                                        id="textBuscar"
                                        name="textBuscar"
                                        style={{
                                            borderColor: "transparent",
                                            backgroundColor: "transparent",
                                            borderBottom: `solid 0.5px ${colors.azul}`,
                                            boxShadow: "none",
                                            fontSize: "18px",
                                            marginTop: "-5px"
                                        }}
                                        value={this.state.textBuscar}

                                        onChange={(event) => {
                                            let { value } = event.target;
                                            this.setState({
                                                textBuscar: value
                                            });
                                        }}
                                        onKeyDown={(event) => {
                                            let { key } = event;
                                            if (key === "Enter") {
                                                this.getUserList(0);
                                            }
                                        }}
                                    />
                                    <InputGroupText
                                        style={{
                                            backgroundColor: "transparent",
                                            borderColor: "transparent",
                                            borderBottom: "solid 0.5px grey",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            this.getUserList(0);
                                        }}
                                    >
                                        <Search
                                            style={{
                                                color: colors.azul
                                            }}
                                        />
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                        md="4"
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                textAlign: "right",
                            }}
                        >
                            <Button
                                title="Actualizar tabla"
                                style={{
                                    backgroundColor: colors.azul,
                                    marginTop: "35px",
                                    marginRight: "15px"
                                }}
                                onClick={() => {
                                    this.setState({
                                        isOpenModaluser: true,
                                        user: {
                                            IdUsuario: 0,
                                            username: "",
                                            password: "",
                                            nombreCompleto: "",
                                            correo: "",
                                        },
                                    })
                                }}
                            >
                                <Add />
                            </Button>
                            <Button
                                title="Actualizar tabla"
                                style={{
                                    backgroundColor: colors.azul,
                                    marginTop: "35px"
                                }}
                                onClick={() => {
                                    this.getUserList(1);
                                }}
                            >
                                <Cached />
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div
                    style={{
                        fontFamily: "monospace",
                        marginTop: "10px",
                        backgroundColor: colors.blanco,
                        marginRight: "10px",
                        marginLeft: "10px",
                        borderRadius: "8px",
                        marginBottom: "50px"
                    }}
                >
                    <div
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px"
                        }}
                    >
                        <Row
                            style={{
                                marginBottom: "10px"
                            }}
                        >
                            <Col>
                                <Label
                                    style={{
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                        fontSize: "22px"
                                    }}
                                >
                                    Lista de usuarios
                                </Label>

                            </Col>
                        </Row>
                        {
                            
                        }
                        <Table
                            columns={this.state.columsUserList}
                            data={this.state.userList}
                            noDataComponent={
                                <label
                                    style={{
                                        fontWeight: "bold"
                                    }}
                                >
                                    tabla vacia
                                </label>
                            }
                            pagination
                        />
                    </div>

                </div>
                <Modal
                    isOpen={this.state.isOpenModaluser}
                    onOpened={() => {
                        components.fucusElement("nombreCompleto");
                    }}
                >

                    <ModalHeader>
                        Datos del Usuario
                    </ModalHeader>

                    <ModalBody>
                        {
                            this.state.textInfo.length === 0 ?
                                null
                                :
                                <Row>
                                    <Col
                                        style={{
                                            textAlign:"center"
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight:"bold",
                                                color:"orange",
                                                fontSize:"18px"
                                            }}
                                        >
                                            {this.state.textInfo}
                                        </Label>
                                    </Col>
                                </Row>
                        }

                        <Row>
                            <Col
                                style={{
                                    marginTop: "10px",
                                }}
                            >
                                <FormGroup>
                                    <Label>
                                        Nombre completo
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="nombreCompleto"
                                            name="nombreCompleto"
                                            type="text"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.user.nombreCompleto}
                                            onChange={(event) => {
                                                this.handlechangeUser(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            this.state.user.IdUsuario === 0 ?
                                <>
                                    <Row>
                                        <Col
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <FormGroup>
                                                <Label>
                                                    Nombre de Usuario
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                        id="username"
                                                        name="username"
                                                        type="text"
                                                        style={{
                                                            borderColor: "transparent",
                                                            backgroundColor: "transparent",
                                                            borderBottom: `solid 0.5px ${colors.azul}`,
                                                            boxShadow: "none",
                                                            fontSize: "18px",
                                                        }}
                                                        value={this.state.user.username}
                                                        onChange={(event) => {
                                                            this.handlechangeUser(event);
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <FormGroup>
                                                <Label>
                                                    Contraseña
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                        id="password"
                                                        name="password"
                                                        type={this.state.isVisiblePassword ? "text" : "password"}
                                                        style={{
                                                            borderColor: "transparent",
                                                            backgroundColor: "transparent",
                                                            borderBottom: `solid 0.5px ${colors.azul}`,
                                                            boxShadow: "none",
                                                            fontSize: "18px",
                                                        }}
                                                        value={this.state.user.password}
                                                        onChange={(event) => {
                                                            this.handlechangeUser(event);
                                                        }}
                                                    />
                                                    <InputGroupText
                                                        onClick={() => {
                                                            this.setState({
                                                                isVisiblePassword: !this.state.isVisiblePassword,
                                                            });
                                                        }}
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            borderColor: "transparent",
                                                            borderBottom: "solid 0.5px grey",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {
                                                            this.state.isVisiblePassword ?
                                                                <Visibility style={{ color: colors.azul }} />
                                                                :
                                                                <VisibilityOff style={{ color: colors.azul }} />
                                                        }
                                                    </InputGroupText>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </>
                                : null
                        }
                        <Row>
                            <Col
                                style={{
                                    marginTop: "10px",
                                }}
                            >
                                <FormGroup>
                                    <Label>
                                        Correo
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="correo"
                                            name="correo"
                                            type="text"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: `solid 0.5px ${colors.azul}`,
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.user.correo}
                                            onChange={(event) => {
                                                this.handlechangeUser(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: "grey",
                                width: "150px"
                            }}
                            onClick={() => {
                                this.setState({
                                    isOpenModaluser: false,
                                })
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                width: "150px"
                            }}
                            onClick={async () => {

                                if(this.state.user.IdUsuario === 0){
                                    if (
                                        this.state.user.username.length === 0 ||
                                        this.state.user.password.length === 0
                                    ) {
                                        toast.warning("Faltan datos por llenar");
                                        this.setState({
                                            textInfo: "Debes de llenar el nombre de usuario y la contraseña como minimo"
                                        })
                                    } else {
                                        let response = await userModel.userSave(this.state.user);
                                        let { status } = response;
                                        if (status === 200) {
                                            await this.getUserList();
                                            toast.success(`Usuario ${this.state.user.IdUsuario === 0 ? "guardado" : "actualizado"}`);
                                            await this.setState({
                                                isOpenModaluser: false,
                                            })
                                        }
                                    }
                                }else{
                                    let response = await userModel.userSave(this.state.user);
                                    let { status } = response;
                                    if (status === 200) {
                                        await this.getUserList();
                                        toast.success(`Usuario ${this.state.user.IdUsuario === 0 ? "guardado" : "actualizado"}`);
                                        await this.setState({
                                            isOpenModaluser: false,
                                        })
                                    }
                                }



                              


                            }}
                        >
                            {this.state.user.IdUsuario === 0 ? "Guardar" : "Actualizar"}
                        </Button>
                    </ModalFooter>

                </Modal>
            </>
        );
    }
}


export default ApplicantList