import axios from "axios";
import API from '../network/api.network';
import swal2 from 'sweetalert2';
import colors from "../config/Colors.config";
import moment from "moment/moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const urlApi = API + "/locality";

const mensajeError = (error) => {
    
    let menssage = error.config.url + ": " + error.message

    
    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}

const mensaje403 = () => {

    let menssage = "Error 403"


    document.body.innerHTML = "<body></body>"

    swal2.fire({
        icon: 'warning',
        title: '¡Sesión caducada!',
        text: "Vuelve a iniciar sesión para poder usar este sistema",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    }).then(() => {
        window.location.href = "/login";
    });
}

const getStateList = async () => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: urlApi + `/states/list`,
            headers: {
                token: await cookies.get("token", {
                    path: "/"
                }),
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}


const getListMunicipioById = async (IdEstado) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: urlApi + `/municipio/list/${IdEstado}`,
            headers: {
                token: await cookies.get("token", {
                    path: "/"
                }),
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const unidadModel = {
    getStateList,
    getListMunicipioById,
}

export default unidadModel;