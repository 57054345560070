import axios from "axios";
import API from '../network/api.network';
import swal2 from 'sweetalert2';
import colors from "../config/Colors.config";
import moment from "moment/moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const urlApi = API + "/postulante";

const mensajeError = (error) => {
    
    let menssage = error.config.url + ": " + error.message
    
    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}

const mensaje403 = () => {
    
    let menssage = "Error 403"

    
    document.body.innerHTML = "<body></body>"

    swal2.fire({
        icon: 'warning',
        title: '¡Sesión caducada!',
        text: "Vuelve a iniciar sesión para poder usar este sistema",
        //timer: "5000",
        confirmButtonColor: colors.azul,            
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    }).then(()=>{
        window.location.href="/login";
    });
}

const guardarPostulante = async (postulante) => {

    let response;
    let data = null;
    
    try {
        response = await axios({
            method: "POST",
            url: urlApi + `/guardar`,
            data: {
                postulante,
            },
            headers:{
                token : await cookies.get("token"),
            }
        })
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data

}

const contPostulanteByPhone = async (phone) => {
    
    let response;
    let data = null;
    
    try {
        response = await axios({
            method: "GET",
            url: urlApi + `/count/${phone}`,
            headers:{
                token : await cookies.get("token"),
            }
        })
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data

}

const postulanteDelete = async (row) => {
    
    let response;
    let data = null;
    
    try {
        response = await axios({
            method: "DELETE",
            url: urlApi + `/delete`,
            headers:{
                token : await cookies.get("token"),
            },
            data:{
                data:row
            }
        })
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data

}

const postulanteList = async () => {

    let response;
    let data = null;
    
    try {
        response = await axios({
            method: "POST",
            url: urlApi + `/list`,
            headers:{
                token : await cookies.get("token",{path:"/"}),
            },
            data:{
                IdFranquicia :  await cookies.get("user",{path:"/"}).IdFranquicia, 
            }
        });     
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }
    
    if(data.status === 403){
        mensaje403();
        return null;
    }

    return data

}

const calcularEdad = (fecha) => {
    
    let nacimiento = moment(fecha);
    let hoy = moment();
    let anios = hoy.diff(nacimiento, "years");
    
    if(anios.toString() == "NaN" ){
        return "";
    }else{
        return anios + " años";
    }
    
}

const searchPostulante = async (tipoBusqueda,referencia) => {

    let response;
    let data = null;
    
    try {
        response = await axios({
            method: "POST",
            url: urlApi + `/search`,
            headers:{
                token : await cookies.get("token",{path:"/"}),
            },
            data:{
                data:{
                    tipoBusqueda,
                    referencia
                }                
            }
        });     
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }
    
    if(data.status === 403){
        mensaje403();
        return null;
    }

    return data

}


const postulanteModel = {
    guardarPostulante,
    contPostulanteByPhone,
    postulanteList,
    calcularEdad,
    postulanteDelete,
    searchPostulante,
}

export default postulanteModel;