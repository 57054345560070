
import React, { useEffect, useState } from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Navbar,
    Nav,
    Button
} from 'reactstrap';
import {

    Apps,
    Person,
    ExitToApp,
    AccountCircle,

    FormatAlignJustify,
    Store,

} from '@material-ui/icons';
import colors from "../config/Colors.config";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


const NavBarComputer = (props) => {

    const [tipoUsuario, setTipoUsuario] = useState(0)

    const [style] = useState({
        backgroundColor: colors.blanco,
        color: colors.azul,
        fontWeight: "bold",
        border: "none",
        boxShadow: "none"
    });

    const [styleIcon] = useState({
        marginTop: "-5px",
        marginRight: "5px",
        color: colors.azul
    });
    const [styleIconSecundari] = useState({
        marginTop: "-5px",
        marginRight: "5px",
        color: colors.azul
    });

    const logOut = () => {
        cookies.remove('user', { path: "/" });
        window.location.href = "/login";
    }

    useEffect( () => {
        let tipoUsuario =  cookies.get("user", { path: "/" }).IdTipoUsuario;
        
        setTipoUsuario(tipoUsuario);
    }, []);

    return (
        <>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    zIndex: "100",
                    fontFamily: "monospace"
                }}
            >
                <Navbar
                    expand="md"
                    style={{

                        backgroundColor: colors.blanco,
                        height: "62px"
                    }}>
                    <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                        {/* <NavItem>
                            <NavLink style={{ fontWeight: "bold", color: "#016390" }} href="/"><Home style={styleIcon} />Cursos</NavLink>
                        </NavItem> */}

                        {/* <UncontrolledDropdown
                            className="me-2"
                            direction="down"
                            setActiveFromChild
                        >
                            <DropdownToggle
                                caret
                                style={style}
                            >
                                <Group style={styleIcon} />Usuario
                            </DropdownToggle>
                            <DropdownMenu style={styleIcon} onMouseOver={(() => {  })} >
                                <DropdownItem href='/user-list'>
                                    <List style={styleIconSecundari} />Listar Usuarios
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem
                                    onClick={() => {
                                        cookies.set("addUser", true, { path: "/" });
                                        window.location.href = '/user-list'
                                    }}
                                >
                                    <PersonAdd style={styleIconSecundari} />Agregar Usuario
                                </DropdownItem>
                                {/* <DropdownItem divider />
                                    <DropdownItem onClick={() => { window.location.href = '/historial-usuarios' }}>
                                        <Update style={styleIcon} />Ver Hisotial
                                    </DropdownItem> 
                            </DropdownMenu>
                        </UncontrolledDropdown> */}

                        {/* <UncontrolledDropdown
                            className="me-2"
                            direction="down"
                            setActiveFromChild
                        >
                            <DropdownToggle
                                caret
                                style={style}

                            >
                                <ViewCarousel style={styleIcon} />Postulantes
                            </DropdownToggle>
                            <DropdownMenu>

                                <DropdownItem href="/list">
                                    <List style={styleIconSecundari} />Listar postulantes
                                </DropdownItem>

                                {/* <DropdownItem divider />
                                <DropdownItem
                                    stle={styleIconSecundari}
                                    onClick={() => {
                                        cookies.set("addconstCenter", true, { path: "/" });
                                        window.location.href = "/const-center-list";

                                    }}
                                >
                                    <Add style={styleIconSecundari} />
                                    Agregar unidad
                                </DropdownItem> 

                            </DropdownMenu>
                        </UncontrolledDropdown> */}
                        {
                            tipoUsuario === 1 ?
                                <Button
                                    style={{
                                        backgroundColor: colors.blanco,
                                        color: colors.azul,
                                        boxShadow: "none",
                                        fontWeight: "bold",
                                        borderColor: colors.blanco,
                                    }}
                                    onClick={() => {
                                        window.location.href = "/user-list";
                                    }}
                                >
                                    <AccountCircle style={styleIconSecundari} />
                                    Usuarios
                                </Button>
                                :
                                null
                        }
                        
                        <Button
                            style={{
                                backgroundColor: colors.blanco,
                                color: colors.azul,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderColor: colors.blanco,
                            }}
                            onClick={() => {
                                window.location.href = "/cost-center";
                            }}
                            title={"Centros de costo"}
                        >
                            <Store style={styleIconSecundari} />
                            Unidades
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.blanco,
                                color: colors.azul,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderColor: colors.blanco,
                            }}
                            onClick={() => {
                                window.location.href = "/list";
                            }}
                        >
                            <Person style={styleIconSecundari} />
                            Postulantes
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.blanco,
                                color: colors.azul,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderColor: colors.blanco,
                            }}
                            onClick={() => {
                                // window.location.href = "/";
                                window.open("/", '_blank')
                            }}
                        >
                            <FormatAlignJustify style={styleIconSecundari} />
                            Formulario
                        </Button>




                        {/* <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    caret
                                    style={style}
                                >
                                    <CreditCard style={styleIcon} />Creditos
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href='/lista-credito'>
                                        <List style={styleIcon} />Ver Creditos
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem href="/solicitud-credito">
                                        <MonetizationOn style={styleIcon} />Solicitar Credito
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown> */}
                        {/* <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    caret
                                    style={style}
                                >
                                    <Description style={styleIcon} /> Fichas de Pago
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href='/ficha-pago-list'>
                                        <List style={styleIcon} /> Ver Fichas de Pago
                                    </DropdownItem>
                                    <DropdownItem href='/ficha-deposito'>
                                        <InsertDriveFile style={styleIcon} /> Generar Ficha de Pago
                                    </DropdownItem>
                                    <DropdownItem href='/pagar-ficha-pago'>
                                        <AttachMoney style={styleIcon} /> Pagar Ficha de Pago
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown> */}
                    </Nav>
                    <UncontrolledDropdown>
                        <DropdownToggle
                            //caret
                            style={style}
                        >
                            <Apps />
                        </DropdownToggle>
                        <DropdownMenu>

                            {/* <DropdownItem href='/my-profile'>
                                <Person style={styleIconSecundari} /> Mi Perfil
                            </DropdownItem>
                            <DropdownItem divider /> */}
                            {/* <DropdownItem href='/login-administrador'>
                                        <PersonOutline style={styleIcon} /> Login
                                    </DropdownItem> */}
                            <DropdownItem onClick={() => logOut()}>
                                <ExitToApp style={styleIconSecundari} /> Cerrar Sesion
                            </DropdownItem>


                            {/*  */}
                            {/* <DropdownItem onClick={() => {}}>
                                    <ExitToApp style={styleIcon} />Cerrar sesion
                                </DropdownItem> */}
                            {/* <DropdownItem divider /> */}
                            {/* <UncontrolledDropdown
                                    className="me-2"
                                    direction="start"
                                >
                                    <DropdownToggle
                                        caret
                                        //color="primary"
                                        style={style}
                                    >
                                        Dropstart
                                    </DropdownToggle>
                                     <DropdownMenu>
                                        <DropdownItem>
                                            Another Action
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>
                                            Another Action
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>  */}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Navbar>
            </div>
        </>
    );
}

export default NavBarComputer;