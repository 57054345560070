import { Component } from "react";
import colors from "../../config/Colors.config";
import {
    Row,
    Col,
    Label,
    Input,
    InputGroup,
    InputGroupText,
    FormGroup,
    Button
} from "reactstrap";
import {
    AccountCircle,
    Lock,
    VisibilityOff,
    Visibility,
    LockOpen,
} from '@material-ui/icons';
import components from "../../tools/components.tool"
import swal2 from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userModel from "../../models/user.model";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            colorInfoValidation: "",
            user: {
                username: "",
                password: ""
            },
            textInfoValidation: "",
            isMobileDevice: null,
            isDesktopDevice: null,
            screenHeight: "",
            screenWidth: "",
            colorIconAccountCircle: "#FFFFFF",
            backgroundColorIconAccountCircle: "#40C451",
            isVisiblePassword: false,
        }
    }

    componentDidMount() {
        console.clear();



        // eslint-disable-next-line no-restricted-globals
        let screenHeight = screen.height.toString();
        // eslint-disable-next-line no-restricted-globals
        let screenWidth = screen.width.toString();
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);
        let isDesktopDevice = !isMobileDevice;





        this.setState({
            isMobileDevice,
            isDesktopDevice,
            screenHeight,
            screenWidth,
        });

        document.body.style.backgroundColor = colors.azul

        components.fucusElement("username");


    }

    handleChangeUser = async (event) => {

        let { name, value } = event.target;
        
        await this.setState({
            user: {
                ...this.state.user,
                [name]: value,
            },
            // textFiel: {
            //     ...this.state.textFiel,
            //     [name]: value == "" ? true : false,
            // }
            // error={this.state.textFiel.username}
        });

        

    }

    iniciarSesionUser = async () => {
        let IdMenssage = 0;
        toast.clearWaitingQueue();
        IdMenssage = toast.loading(<label style={{ fontWeight: "bold" }} >Iniciando Sesíon...</label>, { autoClose: false, });

        let { username, password } = this.state.user;
        let invalid = false;

        if (username === "" && password === "") {
            // empty username and password
            invalid = true;
            this.setState({
                textInfoValidation: "Nombre de usuario y contraseña vacios",
                colorInfoValidation: "orange"
            });
        } else {
            if (username === "") {
                // empty username and password
                invalid = true;
                this.setState({
                    textInfoValidation: "Nombre de usuario vacio",
                    colorInfoValidation: "orange"
                });
            } else {
                if (password === "") {
                    // empty username and password
                    invalid = true;
                    this.setState({
                        textInfoValidation: "contraseña vacio",
                        colorInfoValidation: "orange"
                    });
                }
            }
        }

        if (invalid) {
            toast.dismiss(IdMenssage);
            IdMenssage = toast.warning(<label style={{ fontWeight: "bold" }} >Faltan datos.</label>);
            return;
        }


        await components.sleep(1);
        let response = await userModel.userLogin(this.state.user);
        toast.dismiss(IdMenssage);
        
        let { statusUser } = response;
        

        if(statusUser === 201){
            swal2.fire({
                icon: 'error',
                title: '¡Acceso denegado!',
                text: "No se encontro un perfil de usuario con ese nombre de usuario y/o contraseña",
                //timer: "5000",
                confirmButtonColor: colors.azul,            
                confirmButtonText: 'Okey',
                allowEscapeKey: true,
                timerProgressBar: true,
                // footer: `<p style="text-align: center; color: ${colors.azul};">Contáctate con los administradores</p>`
            });
        }

        // if(statusUser === 404){
        //     toast.error("Acceso denegado");
        //     this.setState({
        //         textInfoValidation: "Nombre de usuario y/o contraseña incorrectos",
        //         colorInfoValidation : "red"
        //     });
        // }

        if (statusUser === 200) {
            let { user } = response;
            cookies.set("user", user, { path: "/" })
            cookies.set("token", user.token, { path: "/" })
            window.location.href = '/list'
        }

    }


    render() {
        return (
            <>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div>
                    <div
                        style={{
                            width: "96%",
                            height: "675px",
                            marginRight: "2%",
                            marginLeft: "2%",
                            marginTop: "5%",
                            backgroundColor: colors.blanco,
                            borderRadius: "8px",
                            // fontFamily:"monospace"
                        }}
                    >
                        <Row>
                            <Col
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/3/3e/Domino%27s_pizza_logo.svg"
                                    alt="No se pudo cargar la imagen"
                                    height={115}
                                    style={{
                                        marginTop: "20px",
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Label
                                    style={{
                                        color: "#000",
                                        fontSize: "48px",
                                        fontWeight: "bold",

                                    }}
                                >
                                    Login
                                </Label>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <center>
                                    <Label
                                        style={{
                                            color: this.state.colorInfoValidation,
                                            fontWeight: "bold",
                                            fontSize: "20px"
                                        }}
                                    >
                                        {this.state.textInfoValidation}
                                    </Label>
                                </center>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: this.state.textInfoValidation.length > 0 ? "16px" : "10px",
                            }}
                        >
                            <Col
                                style={{
                                    // textAlign: "center"
                                }}
                            >
                                <FormGroup>
                                    <Label
                                        style={{
                                            marginLeft: "5%",
                                            fontSize: "20px",
                                            color: "#000",
                                        }}
                                    >
                                        Nombre de Usuario
                                    </Label>
                                    <InputGroup
                                        style={{
                                            width: "90%",
                                            marginRight: "5%",
                                            marginLeft: "5%",
                                            marginTop: "-10px",
                                        }}
                                    >
                                        <InputGroupText
                                            style={{
                                                backgroundColor: "transparent",
                                                borderColor: "transparent",
                                                borderBottom: "solid 0.5px grey",
                                            }}
                                        >
                                            <AccountCircle style={{color:colors.azul}} />
                                        </InputGroupText>
                                        <Input
                                            //placeholder="Escribe tu nombre de usuario"    
                                            id="username"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: "solid 0.5px grey",
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.user.username}
                                            name="username"
                                            onChange={(event) => {
                                                this.handleChangeUser(event);
                                            }}
                                            onKeyDown={(event) => {
                                                let { key } = event;
                                                
                                                if (key === "Enter") {
                                                    components.fucusElement("password");
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                                //backgroundColor:"green"
                            }}
                        >
                            <Col
                                style={{
                                    // textAlign: "center"
                                }}
                            >
                                <FormGroup>
                                    <Label
                                        style={{
                                            marginLeft: "5%",
                                            fontSize: "20px",
                                            color: "#000",
                                        }}
                                    >
                                        Contraseña
                                    </Label>
                                    <InputGroup
                                        style={{
                                            width: "90%",
                                            marginRight: "5%",
                                            marginLeft: "5%",
                                            marginTop: "-10px",
                                        }}
                                    >
                                        <InputGroupText
                                            style={{
                                                backgroundColor: "transparent",
                                                borderColor: "transparent",
                                                borderBottom: "solid 0.5px grey",
                                            }}
                                        >
                                            {this.state.isVisiblePassword ?
                                                <LockOpen style={{color:colors.azul}}/>
                                                :
                                                <Lock style={{color:colors.azul}}/>
                                            }
                                        </InputGroupText>
                                        <Input
                                            id="password"
                                            style={{
                                                borderColor: "transparent",
                                                backgroundColor: "transparent",
                                                borderBottom: "solid 0.5px grey",
                                                boxShadow: "none",
                                                fontSize: "18px",
                                            }}
                                            value={this.state.user.password}
                                            type={this.state.isVisiblePassword ? "text" : "password"}
                                            name="password"
                                            onChange={(event) => {
                                                this.handleChangeUser(event);
                                            }}
                                            onKeyDown={(event) => {
                                                let { key } = event;
                                                if (key === "Enter") {
                                                    this.iniciarSesionUser();
                                                }
                                            }}
                                        />
                                        <InputGroupText
                                            onClick={() => {
                                                this.setState({
                                                    isVisiblePassword: !this.state.isVisiblePassword,
                                                });
                                            }}
                                            style={{
                                                backgroundColor: "transparent",
                                                borderColor: "transparent",
                                                borderBottom: "solid 0.5px grey",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {
                                                this.state.isVisiblePassword ?
                                                    <Visibility style={{color:colors.azul}}/>
                                                    :
                                                    <VisibilityOff style={{color:colors.azul}}/>
                                            }
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "20px",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                <Button
                                    style={{
                                        marginLeft: "10%",
                                        width: "90%",
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                        boxShadow: "none",
                                        backgroundColor: colors.blanco,
                                        borderColor: colors.azul,
                                        color: colors.azul,
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            user: {
                                                username: "",
                                                password: ""
                                            },
                                            textInfoValidation: ""
                                        });
                                        components.fucusElement("username");
                                    }}
                                >
                                    <label>Cancelar</label>
                                </Button>
                            </Col>
                            <Col
                                style={{
                                    textAlign: "right",
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        this.iniciarSesionUser();
                                    }}
                                    style={{
                                        fontSize: "18px",
                                        width: "90%",
                                        marginRight: "10%",
                                        fontWeight: "bold",
                                        boxShadow: "none",
                                        backgroundColor: colors.azul,
                                        borderColor: colors.azul,
                                        color: colors.blanco,
                                    }}
                                >
                                    <label>Iniciar</label>
                                </Button>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginTop: "40px",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "24px",
                                        color: colors.azul,
                                    }}
                                >
                                    Domino's GPM
                                    <br />
                                    <label
                                        style={{
                                            fontSize: "20px",
                                        }}
                                    >
                                        2023
                                    </label>
                                </Label>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }

}


export default Login;

