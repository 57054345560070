const isProduction = true;
var API = "";

if (isProduction) {
    
    // API = "https://lddevsoft.com/api";
    API = "https://postulate.dominosgpm.com.mx/api"

} else {

    const IP = "127.0.0.1";
    // const IP = "192.168.1.78";
    // const IP = "192.168.3.21";

    const PORT = "7000";

    API = `http://${IP}:${PORT}/api`;

}

export default API;