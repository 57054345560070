import { Component } from "react";
import colors from "../../config/Colors.config";
import components from "../../tools/components.tool";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import localityModel from "../../models/locality.model";
import postulanteModel from "../../models/postulante.model";
import unidadModel from "../../models/unit.model";
import imgLogo from "../../assets/images/dominosLogo.png";
import swal2 from 'sweetalert2';
import {
    Row,
    FormGroup,
    Label,
    // InputGroupText,
    Input,
    Col,
    InputGroup,
    Button,
    // Progress,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
    // Spinner,
} from "reactstrap";
class ApplicantForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            isOpenModalEspesificarPersona: false,
            option: 1,
            stateList: [],
            municipioList: [],
            unidadList: [],
            date: {
                year: "",
                mounth: "",
                day: "",
            },
            invalidDay: false,
            invalidMount: false,
            invalidYear: false,
            isOpenModaldate: false,
            showButtonSave: true,
            isOpenModal: false,
            dataApplicant: {
                IdPostulante: "",
                nombreCompleto: "",
                direccion: "",
                telefonoCelular: "",
                correo: "",
                fechaNacimiento: "0000-00-00",
                estadoCivil: "Soltero",
                RFC: "",
                turnoDeseado: "Disponibilidad de horario",
                empleo: "Fijo",
                puestoSolicitado: "Servicio al cliente",
                disponibilidad: "Inmediata",
                experienciaLaboral: "",
                estudios: "Secundaria",
                ultimoTrabajo: "",
                depentdeDeAlguienEconomicamente: 0,
                especificacionDeDependenciaEconomica: "",
                razonesParaContratarte: "",
                trabajoEnDominosAntes: 0,
                trabajaActualmente: 0,
                sucursalDominosAntes: "",
                fechaSalidaDominosAntes: "0000-00-00",
                porqueContratarlo: "",
                tiempoTrabajandoDiminos: "",
                personaDeQuienDepende: "",
                UDN: 0,
                sexo: 0,
                IdEstado: 0,
                IdMunicipio: 0,
            },
            validDataApplicant: {
                nombreCompleto: false,
                direccion: false,
                telefonoCelular: false,
                correo: false,
                experienciaLaboral: false,
                ultimoTrabajo: false,
                especificacionDeDependenciaEconomica: false,
                razonesParaContratarte: false,
                sucursalDominosAntes: false,
                porqueContratarlo: false,
                tiempoTrabajandoDiminos: false,
                personaDeQuienDepende: false,
                UDN: false,
                sexo: false,
                IdEstado: false,
                IdMunicipio: false,
            },
            arrayMaritalStatuses: [
                "Soltero",
                "Casado",
                "Divorciado",
                "Separación en proceso judicial",
                "Viudo",
                "Concubinato",
            ],
            arrayDesiredShift: [
                "Disponibilidad de horario",
                "Matutino",
                "Vespertino",
            ],
            arrayJobType: [
                "Fijo",
                "Eventual"
            ],
            arrayStudies: [
                "Secundaria",
                "Preparatoria cursando",
                "Preparatoria terminada/truncada",
                "Licenciatura cursando",
                "Licenciatura terminada/truncada",
            ],
            arrayAvailability: [
                "Inmediata",
                "Quiero estar en la bolsa de trabajo."
            ],
            arrayRequiredPosition: [
                "Servicio al cliente",
                "Producción",
                "Reparto",
            ]
        }
    }

    async componentDidMount() {
        document.body.style.backgroundColor = colors.azul;
        components.fucusElement("nombreCompleto");

       
        let IdFranquicia = window.location.href.split('/')[3].replace("%20"," ")

        

        if(IdFranquicia.length === 0 ){
            IdFranquicia = 0
        }
        
        let response = await unidadModel.getUnitList(IdFranquicia);
        let unidadList = response.unidadList;

        response = await localityModel.getStateList();
        let stateList = response.states;



        await this.setState({
            unidadList,
            stateList
        });

        document.addEventListener('keyup', function (event) {
            let { key } = event;
            if (key === "F10") {
                window.location.href = "/login";
            }
        });
        //stateList

        document.addEventListener('keydown', e => {
            if (e.ctrlKey && e.key === 's') {
                // Prevent the Save dialog to open
                e.preventDefault();
                // Place your code here
                window.location.href = "/login";
            }
        });
    }

    handlechangeApplicant = async (event) => {
        let { name, value } = event.target;

        if (value.length > 200) {
            return;
        }
        if(value.charAt(value.length - 1) == "'"){
            return
        }
        await this.setState({
            dataApplicant: {
                ...this.state.dataApplicant,
                [name]: value
            },
            validDataApplicant: {
                ...this.state.validDataApplicant,
                [name]: value.length === 0 ? true : false
            }
        });
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div
                    style={{
                        backgroundColor: colors.blanco,
                        marginTop: "20px",
                        marginRight: "10px",
                        marginLeft: "10px",
                        borderRadius: "8px"

                    }}
                >
                    <Row
                        style={{

                        }}
                    >
                        <Col
                            style={{
                                // marginTop: "10px",

                            }}
                        >
                            <center
                                style={{
                                    backgroundColor: "#122f51",
                                    borderRadius: "8px 8px 0px 0px"
                                }}
                            >
                                <Label
                                    style={{
                                        fontSize: "28px",
                                        fontWeight: "bold",
                                        // WebkitTextStroke: "0.5px #000",         
                                        color: colors.blanco,
                                        marginTop: "20px"
                                    }}
                                >
                                    ¿Quieres ser parte de nuestro equipo wow?
                                </Label>
                                <br />
                                <Label
                                    style={{
                                        fontSize: "26px",
                                        color: "yellow",
                                        fontWeight: "bold",
                                        WebkitTextStroke: "0.5px #000",
                                        marginBottom: "20px"
                                    }}
                                >
                                    ¡Déjanos tus datos!
                                </Label>
                                <br />
                                <img src={imgLogo} alt="No se pudo cargar la imagen" height={70} style={{marginBottom:"15px"}}/>                                
                            </center>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <Col
                            style={{
                                marginTop: "10px",
                            }}
                            md="3"
                        >
                            <FormGroup>
                                <Label>
                                    Nombre(s) y Apellidos
                                </Label>
                                <InputGroup>
                                    <Input
                                        focus
                                        id="nombreCompleto"
                                        name="nombreCompleto"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.nombreCompleto}
                                        invalid={this.state.validDataApplicant.nombreCompleto}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                        onKeyDown={(event) => {
                                            let { key } = event;
                                            if (key === "F10") {
                                                window.location.href = "/login";
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Dirección
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="direccion"
                                        name="direccion"
                                        placeholder="calle, núm, colonia, municipio"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.direccion}
                                        invalid={this.state.validDataApplicant.direccion}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Teléfono / Celular
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="telefonoCelular"
                                        name="telefonoCelular"
                                        type={this.props.isDesktopDevice ? "text" : "number"}
                                        value={this.state.dataApplicant.telefonoCelular}
                                        invalid={this.state.validDataApplicant.telefonoCelular}
                                        onChange={(event) => {
                                            let { value } = event.target;
                                            if (["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(value.charAt(value.length - 1))) {
                                                this.handlechangeApplicant(event);
                                            }
                                        }}
                                        onKeyDown={(event) => {
                                            let { key } = event;
                                            if (key === "Backspace") {
                                                let telefonoCelular = this.state.dataApplicant.telefonoCelular;
                                                telefonoCelular = telefonoCelular.slice(0, -1)
                                                this.setState({
                                                    dataApplicant: {
                                                        ...this.state.dataApplicant,
                                                        telefonoCelular
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Correo
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="correo"
                                        name="correo"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.correo}
                                        invalid={this.state.validDataApplicant.correo}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                        type="text"
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Fecha de Nacimiento
                                </Label>
                                <InputGroup>

                                    <Input
                                        id="fechaNacimiento"
                                        name="fechaNacimiento"
                                        placeholder="DD/MM/AAAA"
                                        type="text"
                                        value={this.state.dataApplicant.fechaNacimiento}
                                        invalid={this.state.validDataApplicant.fechaNacimiento}
                                        // onChange={(event) => {
                                        //     this.handlechangeApplicant(event);
                                        // }}
                                        min="1973-01-01"
                                        max="2008-12-31"
                                        onClick={async () => {
                                            await this.setState({
                                                isOpenModaldate: true,
                                            });
                                        }}

                                    />


                                    {/* <jb-date-input
                                        label="date label"
                                        value={this.state.validDataApplicant.fechaNacimiento}
                                    /> */}
                                    {/* <DatePicker
                                        locale="es"
                                        selected={this.state.validDataApplicant.fechaNacimiento}
                                        onChange={(event) => {
                                            // this.handlechangeApplicant(event);
                                        }}
                                        showTimeSelect
                                        dateFormat="Pp"
                                    /> */}
                                    {/* <DateInput
                                        date={this.state.dataApplicant.fechaNacimiento}
                                        format='YYYYMMDD'
                                        separator='-'
                                        onChange={(value)=>{
                                            
                                        }}
                                        style={{
                                            width:"100%"
                                        }}
                                    /> */}
                                    {/* <DatePicker
                                        id="fechaNacimiento"
                                        name="fechaNacimiento"
                                        type="date"
                                        value={new Date(this.state.dataApplicant.fechaNacimiento)}
                                        minDate={new Date("1973-01-01")}
                                        maxDate={new Date("2007-12-31")}
                                        onChange={async (value) => {                                            
                                            
                                            let date = new  Date(value)
                                            let year = date.getFullYear();
                                            let month = date.getMonth()+1
                                            let day = date.getDate();

                                            if(month < 10){
                                                month = "0" + month
                                            }

                                            if(day < 10){
                                                day = "0" + day
                                            }

                                            date = year + "-" + month + "-" + day

                                           
                                            if(!(year < 999)){
                                                await this.setState({
                                                    dataApplicant: {
                                                        ...this.state.dataApplicant,
                                                        fechaNacimiento : date,
                                                    },
                                                });
                                            }
                                            
                                        }}
                                        // value={this.state.dataApplicant.fechaNacimiento}
                                        // invalid={this.state.validDataApplicant.fechaNacimiento}
                                        // onChange={(event) => {
                                        //     this.handlechangeApplicant(event);
                                        // }}
                                        // min="1973-01-01"
                                        // max="2008-12-31"
                                    /> */}
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Estado civil
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="estadoCivil"
                                        name="estadoCivil"
                                        type="select"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.estadoCivil}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                    >
                                        {
                                            this.state.arrayMaritalStatuses.map((value) => {
                                                return (
                                                    <option value={value}>{value}</option>
                                                );
                                            })
                                        }

                                    </Input>

                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Turno deseado
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="turnoDeseado"
                                        name="turnoDeseado"
                                        type="select"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.turnoDeseado}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                    >
                                        {
                                            this.state.arrayDesiredShift.map((value) => {
                                                return (
                                                    <option value={value}>{value}</option>
                                                );
                                            })
                                        }
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Empleo
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="empleo"
                                        name="empleo"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.empleo}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                        type="select"
                                    > {
                                            this.state.arrayJobType.map((value) => {
                                                return (
                                                    <option value={value}>{value}</option>
                                                );
                                            })
                                        }
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Estudios
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="estudios"
                                        name="estudios"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.estudios}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                        type="select"
                                    > {
                                            this.state.arrayStudies.map((value) => {
                                                return (
                                                    <option value={value}>{value}</option>
                                                );
                                            })
                                        }
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Disponibilidad
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="disponibilidad"
                                        name="disponibilidad"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.disponibilidad}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                        type="select"
                                    > {
                                            this.state.arrayAvailability.map((value) => {
                                                return (
                                                    <option value={value}>{value}</option>
                                                );
                                            })
                                        }
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    ¿Qué puesto te gustaría desempeñar?
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="puestoSolicitado"
                                        name="puestoSolicitado"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.puestoSolicitado}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                        type="select"
                                    > {
                                            this.state.arrayRequiredPosition.map((value) => {
                                                return (
                                                    <option value={value}>{value}</option>
                                                );
                                            })
                                        }
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            style={{
                                marginTop: "10px",
                            }}
                            md="3"
                        >
                            <FormGroup>
                                <Label>
                                    Experiencia laboral
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="experienciaLaboral"
                                        name="experienciaLaboral"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.experienciaLaboral}
                                        invalid={this.state.validDataApplicant.experienciaLaboral}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                            <Label>
                                    ¿Depende alguien de ti económicamente?
                                </Label>
                                <InputGroup
                                    style={{
                                        cursor: "pointer"
                                    }}
                                >
                                    <Input
                                        type="checkbox"
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        checked={this.state.dataApplicant.depentdeDeAlguienEconomicamente === 1 ? true : false}
                                        onChange={async () => {
                                            await this.setState({
                                                isOpenModalEspesificarPersona: true,
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    depentdeDeAlguienEconomicamente: this.state.dataApplicant.depentdeDeAlguienEconomicamente === 1 ? 0 : 1,
                                                },
                                            });
                                            await components.fucusElement("personaDeQuienDepende");
                                        }}
                                    />
                                    <Label
                                        style={{
                                            marginLeft: "15px",
                                            marginRight: "15px",
                                            cursor: "pointer",
                                        }}
                                        onClick={async () => {
                                            await this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    depentdeDeAlguienEconomicamente: this.state.dataApplicant.depentdeDeAlguienEconomicamente === 0 ? 1 : 0,
                                                },
                                            });
                                            await components.fucusElement("personaDeQuienDepende");
                                        }}
                                    >
                                        Si
                                    </Label>
                                    <Input
                                        type="checkbox"
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        checked={this.state.dataApplicant.depentdeDeAlguienEconomicamente === 1 ? false : true}
                                        onChange={() => {
                                            this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    depentdeDeAlguienEconomicamente: this.state.dataApplicant.depentdeDeAlguienEconomicamente === 0 ? 1 : 0,
                                                },
                                                validDataApplicant: {
                                                    ...this.state.validDataApplicant,
                                                    personaDeQuienDepende: false
                                                }
                                            });
                                        }}
                                    />
                                    <Label
                                        style={{
                                            marginLeft: "15px",
                                            marginRight: "15px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    depentdeDeAlguienEconomicamente: this.state.dataApplicant.depentdeDeAlguienEconomicamente === 0 ? 1 : 0,
                                                },
                                                validDataApplicant: {
                                                    ...this.state.validDataApplicant,
                                                    personaDeQuienDepende: false
                                                }
                                            });
                                        }}
                                    >
                                        No
                                    </Label>
                                    {/* {
                                        this.state.dataApplicant.depentdeDeAlguienEconomicamente === 1 ?
                                            <Input
                                                id="personaDeQuienDepende"
                                                name="personaDeQuienDepende"
                                                placeholder="¿Quien depende de ti?"
                                                onChange={(event) => {
                                                    this.handlechangeApplicant(event);
                                                }}
                                                value={this.state.dataApplicant.personaDeQuienDepende}
                                                invalid={this.state.validDataApplicant.personaDeQuienDepende}
                                            />
                                            :
                                            null
                                    } */}

                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    ¿Has trabajado en un Dominós antes?
                                </Label>
                                <InputGroup
                                    style={{
                                        cursor: "pointer"
                                    }}
                                >
                                    <Input
                                        type="checkbox"
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        checked={this.state.dataApplicant.trabajoEnDominosAntes === 1 ? true : false}
                                        onChange={async () => {
                                            await this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    trabajoEnDominosAntes: this.state.dataApplicant.trabajoEnDominosAntes === 1 ? 0 : 1,
                                                },
                                                isOpenModal: true,
                                            });
                                        }}
                                    />
                                    <Label
                                        style={{
                                            marginLeft: "15px",
                                            marginRight: "15px",
                                            cursor: "pointer",
                                        }}
                                        onClick={async () => {
                                            await this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    trabajoEnDominosAntes: this.state.dataApplicant.trabajoEnDominosAntes === 0 ? 1 : 0,
                                                },
                                                isOpenModal: true,
                                            });
                                        }}
                                    >
                                        Si
                                    </Label>
                                    <Input
                                        type="checkbox"
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        checked={this.state.dataApplicant.trabajoEnDominosAntes === 1 ? false : true}
                                        onChange={() => {
                                            this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    trabajoEnDominosAntes: this.state.dataApplicant.trabajoEnDominosAntes === 0 ? 1 : 0,
                                                },
                                            });
                                        }}
                                    />
                                    <Label
                                        style={{
                                            marginLeft: "15px",
                                            marginRight: "15px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    trabajoEnDominosAntes: this.state.dataApplicant.trabajoEnDominosAntes === 0 ? 1 : 0,
                                                }
                                            });
                                        }}
                                    >
                                        No
                                    </Label>


                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="6"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    ¿Por qué deberíamos contratarte?
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="porqueContratarlo"
                                        name="porqueContratarlo"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.porqueContratarlo}
                                        invalid={this.state.validDataApplicant.porqueContratarlo}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                        type="text"
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <Col
                            md="3"
                        >
                            <FormGroup>
                                <Label>
                                    RFC (Opcional)
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="RFC"
                                        name="RFC"
                                        style={{

                                        }}
                                        value={this.state.dataApplicant.RFC}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                        type="text"
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                        >
                            <FormGroup>
                                <Label>
                                    Trabaja actualmente
                                </Label>
                                <InputGroup>
                                    <Input
                                        type="checkbox"
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        checked={this.state.dataApplicant.trabajaActualmente === 1 ? true : false}
                                        onChange={async () => {
                                            await this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    trabajaActualmente: this.state.dataApplicant.trabajaActualmente === 1 ? 0 : 1,
                                                },

                                            });
                                        }}
                                    />
                                    <Label
                                        style={{
                                            marginLeft: "15px",
                                            marginRight: "15px",
                                            cursor: "pointer",
                                        }}
                                        onClick={async () => {
                                            await this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    trabajaActualmente: this.state.dataApplicant.trabajaActualmente === 0 ? 1 : 0,
                                                },

                                            });
                                        }}
                                    >
                                        Si
                                    </Label>
                                    <Input
                                        type="checkbox"
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        checked={this.state.dataApplicant.trabajaActualmente === 1 ? false : true}
                                        onChange={() => {
                                            this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    trabajaActualmente: this.state.dataApplicant.trabajaActualmente === 0 ? 1 : 0,
                                                },
                                            });
                                        }}
                                    />
                                    <Label
                                        style={{
                                            marginLeft: "15px",
                                            marginRight: "15px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    trabajaActualmente: this.state.dataApplicant.trabajaActualmente === 0 ? 1 : 0,
                                                }
                                            });
                                        }}
                                    >
                                        No
                                    </Label>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                        >
                            <FormGroup>
                                <Label>
                                    ¿En qué unidad te gustaría trabajar?
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="UDN"
                                        name="UDN"
                                        value={this.state.dataApplicant.UDN}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}

                                        invalid={this.state.validDataApplicant.UDN}
                                        type="select"
                                    >
                                        <option value={0}>Elige una opción</option>
                                        {
                                            this.state.unidadList.map((value) => {
                                                return (
                                                    <option value={value.UDN}>{value.nombreUnidad} ({value.nombreEstado},{value.nombreMunicipio}) </option>
                                                );
                                            })
                                        }
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                        >
                            <FormGroup>
                                <Label>
                                    Sexo
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="sexo"
                                        name="sexo"
                                        value={this.state.dataApplicant.sexo}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                        invalid={this.state.validDataApplicant.sexo}
                                        type="select"
                                    >
                                        <option value={0}>Elige una opción</option>
                                        <option value="Hombre">Hombre</option>
                                        <option value="Mujer">Mujer</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <Col
                            style={{
                                marginTop: "10px",
                            }}
                            md="3"
                        >
                            <FormGroup>
                                <Label>
                                    ¿En qué estado vives?
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="IdEstado"
                                        name="IdEstado"
                                        value={this.state.dataApplicant.IdEstado}
                                        invalid={this.state.validDataApplicant.IdEstado}
                                        onChange={async (event) => {
                                            await this.handlechangeApplicant(event);
                                            let IdEstado = event.target.value;
                                 
                                            let response = await localityModel.getListMunicipioById(IdEstado);
                                            
                                            let municipioList = response.municipios;
                                            await this.setState({
                                                municipioList,
                                                dataApplicant: {
                                                    ...this.state.dataApplicant,
                                                    IdMunicipio: 0
                                                }
                                            });

                                        }}
                                        type="select"
                                    >
                                        <option value={0}>Elige una opción</option>
                                        {
                                            this.state.stateList.map((value) => {
                                                return (
                                                    <option value={value.IdEstado}>{value.nombreEstado}</option>
                                                );
                                            })
                                        }
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    ¿En qué municipio vives?
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="IdMunicipio"
                                        name="IdMunicipio"
                                        value={this.state.dataApplicant.IdMunicipio}
                                        invalid={this.state.validDataApplicant.IdMunicipio}
                                        onChange={(event) => {
                                            this.handlechangeApplicant(event);
                                        }}
                                        type="select"
                                    >
                                        <option value={0}>Elige una opción</option>
                                        {
                                            this.state.municipioList.map((value) => {
                                                return (
                                                    <option value={value.IdMunicipio}>{value.nombreMunicipio}</option>
                                                );
                                            })
                                        }
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row >
                        <Col
                            style={{
                                textAlign: this.state.showButtonSave ? "right" : "center",
                                marginRight: "22px"
                            }}
                        >
                            {
                                this.state.showButtonSave ?
                                    <Button
                                        style={{
                                            backgroundColor: colors.azul,
                                            marginBottom: "20px",
                                            width: "150px"
                                        }}
                                        onClick={async () => {

                                            let flag = false;

                                            if (this.state.dataApplicant.nombreCompleto.length === 0) {
                                                await this.setState({
                                                    validDataApplicant: {
                                                        ...this.state.validDataApplicant,
                                                        nombreCompleto: true,
                                                    }
                                                });
                                                flag = true;
                                                components.fucusElement("nombreCompleto");
                                            }

                                            if (this.state.dataApplicant.telefonoCelular.length === 0) {
                                                await this.setState({
                                                    validDataApplicant: {
                                                        ...this.state.validDataApplicant,
                                                        telefonoCelular: true,
                                                    }
                                                });
                                                flag = true;
                                                components.fucusElement("telefonoCelular");
                                            }

                                            if (this.state.dataApplicant.fechaNacimiento === "0000-00-00") {
                                                await this.setState({
                                                    validDataApplicant: {
                                                        ...this.state.validDataApplicant,
                                                        fechaNacimiento: true,
                                                    }
                                                });
                                                flag = true;
                                                components.fucusElement("telefonoCelular");
                                            }
                                            if (this.state.dataApplicant.UDN === 0) {
                                                await this.setState({
                                                    validDataApplicant: {
                                                        ...this.state.validDataApplicant,
                                                        UDN: true,
                                                    }
                                                });
                                                flag = true;
                                                components.fucusElement("UDN");
                                            }

                                            if (this.state.dataApplicant.sexo === 0) {
                                                await this.setState({
                                                    validDataApplicant: {
                                                        ...this.state.validDataApplicant,
                                                        sexo: true,
                                                    }
                                                });
                                                flag = true;
                                                components.fucusElement("sexo");
                                            }

                                            if (this.state.dataApplicant.IdEstado === 0) {
                                                await this.setState({
                                                    validDataApplicant: {
                                                        ...this.state.validDataApplicant,
                                                        IdEstado: true,
                                                    }
                                                });
                                                flag = true;
                                                components.fucusElement("IdEstado");
                                            }

                                            if (this.state.dataApplicant.IdMunicipio === 0) {
                                                await this.setState({
                                                    validDataApplicant: {
                                                        ...this.state.validDataApplicant,
                                                        IdMunicipio: true,
                                                    }
                                                });
                                                flag = true;
                                                components.fucusElement("IdMunicipio");
                                            }

                                            if (flag) {
                                                toast.warning("Faltan datos por llenar");
                                            } else {
                                                if (this.state.dataApplicant.telefonoCelular.length < 10) {
                                                    toast.warning("Numero de telefono menor a 10 numeros");
                                                    components.fucusElement("telefonoCelular");
                                                } else {

                                                    let response = await postulanteModel.contPostulanteByPhone(this.state.dataApplicant.telefonoCelular);

                                                    let { COUNT } = response;

                                                    if (COUNT >= 1) {
                                                        swal2.fire({
                                                            icon: 'warning',
                                                            title: '¡Atención!',
                                                            text: `Ya hay un postulante registrado con este número de teléfono ${this.state.dataApplicant.telefonoCelular}`,
                                                            //timer: "5000",
                                                            confirmButtonColor: colors.azul,
                                                            confirmButtonText: 'Okey',
                                                            allowEscapeKey: true,
                                                            timerProgressBar: true,
                                                            footer: `<p style="text-align: center; color: ${colors.azul};">Regístrate con otro número de teléfono</p>`
                                                        }).then(() => {
                                                            components.fucusElement("telefonoCelular");
                                                        });
                                                        components.fucusElement("telefonoCelular");

                                                    } else {


                                                        swal2.fire({
                                                            icon: 'question',
                                                            title: '¡Atención!',
                                                            text: `¿Estás seguro de guardar tu información con los datos que aparece en este formulario?`,
                                                            //timer: "5000",
                                                            confirmButtonColor: colors.azul,
                                                            confirmButtonText: 'Sí guardar',
                                                            showCancelButton: true,
                                                            cancelButtonText: "No guardar",
                                                            allowEscapeKey: true,
                                                            timerProgressBar: true,
                                                            reverseButtons: true,
                                                            footer: `<p style="text-align: center; color: ${colors.azul};">No podras actualizar los damso despues</p>`
                                                        }).then(async (value) => {
                                                            if (value.isConfirmed) {
                                                                response = await postulanteModel.guardarPostulante(this.state.dataApplicant);
                                                                let { status } = response;
                                                                if (status) {
                                                                    toast.success("Información guardada.");
                                                                    await this.setState({
                                                                        showButtonSave: false,
                                                                    });
                                                                }
                                                                components.fucusElement("telefonoCelular");
                                                            }

                                                        });


                                                    }


                                                }
                                            }

                                        }}

                                    >
                                        Guardar
                                    </Button>
                                    :
                                    <Label
                                        style={{
                                            color: colors.azul,
                                            fontWeight: "bold",
                                            fontSize: "14px"
                                        }}
                                    >
                                        Tus datos han sido guardados, espera a que una persona te contacte
                                    </Label>
                            }
                        </Col>
                    </Row>

                </div>
                <Modal
                    isOpen={this.state.isOpenModal}
                    style={{
                        marginTop: "30%"
                    }}
                >
                    <ModalHeader>
                        Datos del Dominos donde trabajo
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        Sucursal Dominos
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="sucursalDominosAntes"
                                            name="sucursalDominosAntes"
                                            style={{

                                            }}
                                            value={this.state.dataApplicant.sucursalDominosAntes}
                                            onChange={(event) => {
                                                this.handlechangeApplicant(event);
                                            }}
                                            invalid={this.state.validDataApplicant.sucursalDominosAntes}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        Fecha de Salida
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="fechaSalidaDominosAntes"
                                            name="fechaSalidaDominosAntes"
                                            type="date"
                                            invalid={this.state.validDataApplicant.fechaSalidaDominosAntes}
                                            value={this.state.dataApplicant.fechaSalidaDominosAntes}
                                            onChange={(event) => {
                                                this.handlechangeApplicant(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        ¿Cuanto tiempo trabajo en esta unidad?
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="tiempoTrabajandoDiminos"
                                            name="tiempoTrabajandoDiminos"
                                            type="text"
                                            invalid={this.state.validDataApplicant.tiempoTrabajandoDiminos}
                                            value={this.state.dataApplicant.tiempoTrabajandoDiminos}
                                            onChange={(event) => {
                                                this.handlechangeApplicant(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                width: "100px"
                            }}
                            onClick={async () => {
                                await this.setState({
                                    dataApplicant: {
                                        ...this.state.dataApplicant,
                                        trabajoEnDominosAntes: 0,
                                    },
                                    validDataApplicant: {
                                        sucursalDominosAntes: false,
                                        fechaSalidaDominosAntes: false,
                                    },
                                    isOpenModal: false,
                                });
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{
                                width: "100px",
                                backgroundColor: colors.azul
                            }}
                            onClick={async () => {

                                let flag = false;

                                if (this.state.dataApplicant.sucursalDominosAntes.length === 0) {
                                    await this.setState({
                                        validDataApplicant: {
                                            ...this.state.validDataApplicant,
                                            sucursalDominosAntes: true,
                                        }
                                    });
                                    flag = true;
                                }
                                if (this.state.dataApplicant.fechaSalidaDominosAntes.length === 0) {
                                    await this.setState({
                                        validDataApplicant: {
                                            ...this.state.validDataApplicant,
                                            fechaSalidaDominosAntes: true,
                                        }
                                    });
                                    flag = true;
                                }
                                if (this.state.dataApplicant.tiempoTrabajandoDiminos.length === 0) {
                                    await this.setState({
                                        validDataApplicant: {
                                            ...this.state.validDataApplicant,
                                            tiempoTrabajandoDiminos: true,
                                        }
                                    });
                                    flag = true;
                                }

                                if (flag) {
                                    toast.warning("Faltan datos por espesificar");
                                } else {
                                    this.setState({
                                        isOpenModal: false
                                    });
                                }
                            }}
                        >
                            Guardar
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.isOpenModaldate}
                    style={{
                        marginTop: "5%"
                    }}
                >
                    <ModalHeader
                        toggle={() => {
                            this.setState({
                                isOpenModaldate: false
                            });
                        }}
                    >
                        Establece tu fecha de nacimiento 
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        Fecha de Nacimiento
                                    </Label>
                                    <InputGroup>
                                        {
                                            this.state.option === 2 ?
                                                <Input
                                                    id="fechaNacimiento"
                                                    name="fechaNacimiento"
                                                    type="date"
                                                    value={this.state.dataApplicant.fechaNacimiento}
                                                    invalid={this.state.validDataApplicant.fechaNacimiento}
                                                    onChange={(event) => {
                                                        this.handlechangeApplicant(event);
                                                    }}
                                                    min="1973-01-01"
                                                    max="2008-12-31"
                                                    onClick={async () => {
                                                        await this.setState({
                                                            isOpenModaldate: true,
                                                        });
                                                    }}
                                                />
                                                :
                                                <>
                                                    <Input
                                                        style={{
                                                            width: "30%",
                                                            marginRight: "3%"
                                                        }}
                                                        value={this.state.date.day}
                                                        placeholder="Dia"
                                                        type="number"
                                                        invalid={this.state.invalidDay}
                                                        onChange={(event) => {
                                                            let { value } = event.target;

                                                            if (value.length > 2) {
                                                                components.fucusElement("mes");
                                                                
                                                                if (parseInt(value) > 31) {
                                                                    this.setState({
                                                                        invalidDay: true,
                                                                    });
                                                                }
                                                                return;
                                                            }


                                                            if (value.length === 2) {
                                                                value = parseInt(value);
                                                                if (value < 10) {
                                                                    value = "0" + value
                                                                }

                                                                let date = this.state.dataApplicant.fechaNacimiento;
                                                                date = date.substring(0, 8) + value;

                                                                this.setState({
                                                                    date: {
                                                                        ...this.state.date,
                                                                        day: value
                                                                    },
                                                                    dataApplicant: {
                                                                        ...this.state.dataApplicant,
                                                                        fechaNacimiento: date
                                                                    }
                                                                });

                                                                this.setState({
                                                                    invalidDay: parseInt(value) > 31 ? true : false,
                                                                });

                                                                components.fucusElement("mes");


                                                            } else {
                                                                this.setState({
                                                                    date: {
                                                                        ...this.state.date,
                                                                        day: value
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <Input
                                                        id="mes"
                                                        style={{
                                                            width: "30%",
                                                            marginRight: "3%"
                                                        }}
                                                        placeholder="Mes"
                                                        value={this.state.date.mounth}
                                                        invalid={this.state.invalidMount}
                                                        type="number"
                                                        onChange={(event) => {
                                                            let { value } = event.target;

                                                            if (value.length > 2) {
                                                                components.fucusElement("ano");

                                                                if (parseInt(value) > 12) {
                                                                    this.setState({
                                                                        invalidMount: true,
                                                                    });
                                                                }
                                                                return;
                                                            }


                                                            if (value.length === 2) {
                                                                value = parseInt(value);
                                                                if (value < 10) {
                                                                    value = "0" + value
                                                                }

                                                                let date = this.state.dataApplicant.fechaNacimiento;
                                                                date = date.substring(0, 4) + "-" + value + "-" + date.substring(8, 10);

                                                                this.setState({
                                                                    date: {
                                                                        ...this.state.date,
                                                                        mounth: value
                                                                    },
                                                                    dataApplicant: {
                                                                        ...this.state.dataApplicant,
                                                                        fechaNacimiento: date
                                                                    }
                                                                });

                                                                this.setState({
                                                                    invalidMount: parseInt(value) > 12 ? true : false,
                                                                });

                                                                components.fucusElement("ano");


                                                            } else {
                                                                this.setState({
                                                                    date: {
                                                                        ...this.state.date,
                                                                        mounth: value
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <Input
                                                        id="ano"
                                                        style={{
                                                            width: "30%"
                                                        }}
                                                        value={this.state.date.year}
                                                        placeholder="Año"
                                                        type="number"
                                                        invalid={this.state.invalidYear}
                                                        onChange={(event) => {
                                                            let { value } = event.target;

                                                            let date = this.state.dataApplicant.fechaNacimiento;
                                                            date = value + date.substring(4, 10);

                                                            this.setState({
                                                                invalidYear: value.length === 4 ? false : true,
                                                                dataApplicant: {
                                                                    ...this.state.dataApplicant,
                                                                    fechaNacimiento: value.length === 4 ? date : this.state.dataApplicant.fechaNacimiento
                                                                }
                                                            });




                                                            this.setState({
                                                                date: {
                                                                    ...this.state.date,
                                                                    year: value
                                                                }
                                                            });

                                                        }}
                                                    />
                                                </>
                                        }


                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        Fecha de Nacimiento (DDMMYYYY)
                                    </Label>
                                    <InputGroup>

                                        <Input
                                            style={{
                                                width: "30%",
                                                marginRight: "3%"
                                            }}
                                            value={this.state.date.day}
                                            placeholder="Dia"
                                            type="number"
                                            invalid={this.state.invalidDay}
                                            onChange={(event) => {
                                                let { value } = event.target;

                                                if (value.length > 2) {
                                                    components.fucusElement("mes");
                                                    
                                                    if (parseInt(value) > 31) {
                                                        this.setState({
                                                            invalidDay: true,
                                                        });
                                                    }
                                                    return;
                                                }


                                                if (value.length === 2) {
                                                    value = parseInt(value);
                                                    if (value < 10) {
                                                        value = "0" + value
                                                    }

                                                    let date = this.state.dataApplicant.fechaNacimiento;
                                                    date = date.substring(0, 8) + value;

                                                    this.setState({
                                                        date: {
                                                            ...this.state.date,
                                                            day: value
                                                        },
                                                        dataApplicant: {
                                                            ...this.state.dataApplicant,
                                                            fechaNacimiento: date
                                                        }
                                                    });

                                                    this.setState({
                                                        invalidDay: parseInt(value) > 31 ? true : false,
                                                    });

                                                    components.fucusElement("mes");


                                                } else {
                                                    this.setState({
                                                        date: {
                                                            ...this.state.date,
                                                            day: value
                                                        }
                                                    });
                                                }
                                            }}
                                        />
                                        <Input
                                            id="mes"
                                            style={{
                                                width: "30%",
                                                marginRight: "3%"
                                            }}
                                            placeholder="Mes"
                                            value={this.state.date.mounth}
                                            invalid={this.state.invalidMount}
                                            type="number"
                                            onChange={(event) => {
                                                let { value } = event.target;

                                                if (value.length > 2) {
                                                    components.fucusElement("ano");

                                                    if (parseInt(value) > 12) {
                                                        this.setState({
                                                            invalidMount: true,
                                                        });
                                                    }
                                                    return;
                                                }


                                                if (value.length === 2) {
                                                    value = parseInt(value);
                                                    if (value < 10) {
                                                        value = "0" + value
                                                    }

                                                    let date = this.state.dataApplicant.fechaNacimiento;
                                                    date = date.substring(0, 4) + "-" + value + "-" + date.substring(8, 10);

                                                    this.setState({
                                                        date: {
                                                            ...this.state.date,
                                                            mounth: value
                                                        },
                                                        dataApplicant: {
                                                            ...this.state.dataApplicant,
                                                            fechaNacimiento: date
                                                        }
                                                    });

                                                    this.setState({
                                                        invalidMount: parseInt(value) > 12 ? true : false,
                                                    });

                                                    components.fucusElement("ano");


                                                } else {
                                                    this.setState({
                                                        date: {
                                                            ...this.state.date,
                                                            mounth: value
                                                        }
                                                    });
                                                }
                                            }}
                                        />
                                        <Input
                                            id="ano"
                                            style={{
                                                width: "30%"
                                            }}
                                            value={this.state.date.year}
                                            placeholder="Año"
                                            type="number"
                                            invalid={this.state.invalidYear}
                                            onChange={(event) => {
                                                let { value } = event.target;

                                                let date = this.state.dataApplicant.fechaNacimiento;
                                                date = value + date.substring(4, 10);

                                                this.setState({
                                                    invalidYear: value.length === 4 ? false : true,
                                                    dataApplicant: {
                                                        ...this.state.dataApplicant,
                                                        fechaNacimiento: value.length === 4 ? date : this.state.dataApplicant.fechaNacimiento
                                                    }
                                                });




                                                this.setState({
                                                    date: {
                                                        ...this.state.date,
                                                        year: value
                                                    }
                                                });

                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                this.setState({
                                    option: this.state.option === 1 ? 2 : 1,
                                });
                            }}
                            style={{
                                backgroundColor: colors.azul,
                                width:"150px"
                            }}
                        >
                            Cambiar fomato
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({
                                    isOpenModaldate: false
                                });
                            }}
                            style={{
                                backgroundColor: colors.azul,
                                width:"150px"
                            }}
                        >
                            Cerrar
                        </Button>

                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.isOpenModalEspesificarPersona}
                >
                    <ModalHeader>
                            Datos de la persona que depende de ti
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        ¿Quién depende de ti económicamente?
                                    </Label>
                                    <InputGroup
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    >
                                        <Input
                                            id="personaDeQuienDepende"
                                            name="personaDeQuienDepende"
                                            //placeholder="¿Quien depende de ti?"
                                            onChange={(event) => {
                                                this.handlechangeApplicant(event);
                                            }}
                                            value={this.state.dataApplicant.personaDeQuienDepende}
                                            invalid={this.state.validDataApplicant.personaDeQuienDepende}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{}}
                            onClick={() => {
                                this.setState({
                                    isOpenModalEspesificarPersona: false,
                                    dataApplicant: {
                                        ...this.state.dataApplicant,
                                        depentdeDeAlguienEconomicamente: 0,
                                    },
                                });
                            }}
                        >
                            Cancelar
                        </Button>
                        {
                            this.state.dataApplicant.personaDeQuienDepende.length === 0 ?
                                null
                                :
                                <Button
                                    style={{
                                        backgroundColor: colors.azul
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            isOpenModalEspesificarPersona: false
                                        });
                                    }}
                                >
                                    Acpertar
                                </Button>
                        }

                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default ApplicantForm;