import axios from "axios";
import API from '../network/api.network';
import swal2 from 'sweetalert2';
import colors from "../config/Colors.config";
import moment from "moment/moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const urlApi = API + "/unit";

const mensajeError = (error) => {
    
    let menssage = error.config.url + ": " + error.message

    
    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}

const mensaje403 = () => {

    let menssage = "Error 403"


    document.body.innerHTML = "<body></body>"

    swal2.fire({
        icon: 'warning',
        title: '¡Sesión caducada!',
        text: "Vuelve a iniciar sesión para poder usar este sistema",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    }).then(() => {
        window.location.href = "/login";
    });
}

const getUnitList = async (IdFranquicia) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: urlApi + `/list/${IdFranquicia}`,
            headers: {
                token: await cookies.get("token", {
                    path: "/"
                }),
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const getUnidadList = async (IdFranquicia) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: urlApi + `/listar/unidades`,
            headers: {
                token: await cookies.get("token", {
                    path: "/"
                }),
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const deleteUnidad = async (unidad) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "DELETE",
            url: urlApi + `/delete`,
            headers: {
                token: await cookies.get("token", {
                    path: "/"
                }),
            },
            data:{
                unidad
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}
const getFranquiciaList = async (unidad) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: urlApi + `/franquicia/listar`,
            headers: {
                token: await cookies.get("token", {
                    path: "/"
                }),
            },
            data:{
                unidad
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const saveUnidad = async (unidad) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "POST",
            url: urlApi + `/save`,
            headers: {
                token: await cookies.get("token", {
                    path: "/"
                }),
            },
            data:{
                unidad
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const searchUnidad = async (referencia) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "POST",
            url: urlApi + `/search-unidad`,
            headers: {
                token: await cookies.get("token", {
                    path: "/"
                }),
            },
            data:{
                referencia
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const unidadModel = {
    getUnitList,
    getUnidadList,
    deleteUnidad,
    getFranquiciaList,
    saveUnidad,
    searchUnidad
}

export default unidadModel;