import { Component } from "react"
import colors from "../../config/Colors.config"
import postulanteModel from "../../models/postulante.model";
import DataTimeTool from "../../tools/DataTime.tools";
import NavBar from "../../components/NavBar";
import swal2 from 'sweetalert2';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import unidadModel from "../../models/unit.model";
import {
    Person,
    Cached,
    Delete,
    Clear,
    PictureAsPdf,
    DeleteForever,
    Check,
} from '@material-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import {
    Row,
    FormGroup,
    Label,
    // InputGroupText,
    Input,
    Col,
    InputGroup,
    Button,
    Progress,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Spinner,
    DropdownItem,
} from "reactstrap";
import Table from "react-data-table-component";
import IconButton from "@material-ui/core/IconButton";
import components from "../../tools/components.tool";
import Cookies from "universal-cookie";
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import userModel from "../../models/user.model";

const cookies = new Cookies();
class ApplicantList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombreUnidadAuxiliar : "",
            isOpenModalUnidad: false,
            tipoBusqueda: 1,
            referencia: "",
            urlAnimation: "https://assets10.lottiefiles.com/packages/lf20_gbfwtkzw.json",
            isLoadTable: false,
            dataApplicant: {
                IdPostulante: "",
                nombreCompleto: "",
                direccion: "",
                telefonoCelular: "",
                correo: "",
                fechaNacimiento: "",
                estadoCivil: "",
                RFC: "",
                turnoDeseado: "",
                empleo: "",
                puestoSolicitado: "",
                disponibilidad: "",
                experienciaLaboral: "",
                estudios: "",
                ultimoTrabajo: "",
                depentdeDeAlguienEconomicamente: 0,
                especificacionDeDependenciaEconomica: "",
                razonesParaContratarte: "",
                trabajoEnDominosAntes: 0,
                trabajaActualmente: 0,
                sucursalDominosAntes: "",
                fechaSalidaDominosAntes: "",
                porqueContratarlo: "",
                tiempoTrabajandoDiminos: "",
                personaDeQuienDepende: "",
                sexo: "",
                UDN: "",
                nombreUnidad: "",
                fechaPostulacion: "",
                horaPostulacion: "",
                IdEstado: 0,
                nombreEstado: "",
                IdMunicipio: 0,
                nombreMunicipio: ""
            },
            isOpenModalApplicant: false,
            isChrome: false,
            postulanteList: [],
            columnList: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Fecha y hora</p>,
                    selector: row => <label style={{ fontWeight: "bold" }} title={DataTimeTool.dateFormatoCompleto(row.fechaPostulacion)} >{DataTimeTool.invertirDateFormatoSimple(row.fechaPostulacion) + " " + DataTimeTool.timeFormatoSimple(row.horaPostulacion)}</label>,
                    sortable: true,
                    width: "230px"
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre</p>,
                    selector: row => <label style={{ fontWeight: "bold" }} >{row.nombreCompleto}</label>,
                    sortable: true,
                    width: "250px"
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Sexo</p>,
                    selector: row => <label style={{ fontWeight: "bold" }} >{row.sexo}</label>,
                    sortable: true,
                    width: "100px"
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Teléfono </p>,
                    selector: row =>


                        <label
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {
                                this.state.isChrome ?
                                    <a
                                        title={`Click para marcar a este numero ${row.telefonoCelular}`}
                                        style={{
                                            textDecoration: "none",
                                            color: "#000"
                                        }}
                                        href={this.state.isChrome ? "tel:" + row.telefonoCelular : ""}
                                    >
                                        {row.telefonoCelular}
                                    </a>
                                    :
                                    row.telefonoCelular
                            }

                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Unidad</p>,
                    selector: row =>
                        <label
                            title={row.nombreUnidad + " (" + row.UDN + ")"}
                            style={{ fontWeight: "bold", cursor: "text", cursor: "pointer" }}
                        >
                            {row.nombreUnidad} ({row.UDN})
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Estado Civil</p>,
                    selector: row =>
                        <label style={{ fontWeight: "bold" }} >
                            {row.estadoCivil}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Puesto Solicitado</p>,
                    selector: row =>
                        <label style={{ fontWeight: "bold" }} >
                            {row.puestoSolicitado}
                        </label>,
                    sortable: true
                },
                // {
                //     name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Estudios</p>,
                //     selector: row =>
                //         <label style={{ fontWeight: "bold" }} >
                //             {row.estudios}
                //         </label>,
                //     sortable: true
                // },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Edad Estimada</p>,
                    selector: row =>
                        <label
                            style={{ fontWeight: "bold" }}
                            title={`Fecha de nacimiento ${row.fechaNacimiento.substring(0, 10)}`}
                        >
                            {postulanteModel.calcularEdad(row.fechaNacimiento.substring(0, 10))}
                        </label>,
                    sortable: true
                },


                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Acciones</p>,
                    selector: row =>
                        <>
                            <IconButton
                                title={`Eliminar al postulante ${row.nombreCompleto}`}
                                onClick={async () => {
                                    this.deletePostulante(row);

                                }}
                            >
                                <Delete
                                    style={{
                                        color: colors.rojo
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                title={`Ver mas datos de la persona ${row.nombreCompleto}`}
                                onClick={async () => {

                                    await this.setState({
                                        isOpenModalApplicant: true,
                                        dataApplicant: row
                                    });
                                }}
                            >
                                <Person
                                    style={{
                                        color: colors.azul
                                    }}
                                />
                            </IconButton>
                        </>,
                    sortable: true
                },

            ],
            unidadList: [],
            columnTableUnidades: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>UDN</p>,
                    selector: row =>
                        <label style={{ fontWeight: "" }} >
                            {row.UDN}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Unidad</p>,
                    selector: row =>
                        <label style={{ fontWeight: "" }} >
                            {row.nombreUnidad}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Estado</p>,
                    selector: row =>
                        <label style={{ fontWeight: "" }} >
                            {row.nombreEstado}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Municipio</p>,
                    selector: row =>
                        <label style={{ fontWeight: "" }} >
                            {row.nombreMunicipio}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Acciones</p>,
                    selector: row =>

                        <IconButton
                            onClick={async () => {
                                let event = {
                                    target: {
                                        value: row.UDN,
                                        name: "UDN"
                                    }
                                }
                                // this.handlechangeApplicant(event);
                                await this.setState({
                                    isOpenModalUnidad: false,                                    
                                    nombreUnidadAuxiliar : row.nombreUnidad,
                                    referencia : row.UDN
                                });

                                await this.searchPostulante(7,row.UDN);
                            }}
                        >
                            <Check
                                style={{
                                    color: colors.azul
                                }}
                            />
                        </IconButton>,
                    sortable: true
                },
            ],
            arrayMaritalStatuses: [
                "Soltero",
                "Casado",
                "Divorciado",
                "Separación en proceso judicial",
                "Viudo",
                "Concubinato",
            ],
            arrayStudies: [
                "Secundaria",
                "Preparatoria cursando",
                "Preparatoria terminada/trunca",
                "Licenciatura cursando",
                "Licenciatura terminada/trunca",
            ],
            arrayRequiredPosition: [
                "Servicio al cliente",
                "Producción",
                "Reparto",
            ]
        }
    }

    async componentDidMount() {

        document.body.style.backgroundColor = colors.azul;

        let isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

        this.setState({
            isChrome
        });
        let { IdFranquicia } = cookies.get("user", {
            path: "/"
        });
        let response = await unidadModel.getUnitList(IdFranquicia);
        let unidadList = response.unidadList;
        await this.setState({
            unidadList
        });
        this.getPostulanteList(0);



    }

    deletePostulante = (postulante) => {
        swal2.fire({
            icon: 'question',
            title: '¿Eliminar postulante?',
            text: `¿Estas seguro de que quieres eliminar al postulante ${postulante.nombreCompleto}?`,
            //timer: "5000",
            confirmButtonColor: colors.azul,
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonText: 'Si',
            reverseButtons: true,
            allowEscapeKey: true,
            timerProgressBar: true,
            footer: `<p style="text-align: center; color: ${colors.rojo};">No se podrá recuperar los datos del postulante una vez eliminado</p>`
        }).then(async (value) => {
            if (value.isConfirmed) {
                await postulanteModel.postulanteDelete(postulante);
                await this.getPostulanteList(1)
                await this.setState({
                    isOpenModalApplicant: false
                });
                toast.success("Postulante eliminado");
            }
        });
    }

    getPostulanteList = async (origen) => {
        await this.setState({
            postulanteList: [],
            isLoadTable: true,
            statusTable: 0
        });
        await components.sleep(1);
        let response = await postulanteModel.postulanteList();
        let { postulanteList } = response;
        await this.setState({
            postulanteList,
            isLoadTable: false
        });
        let { length } = postulanteList
        if (origen === 0) {
            if (length === 0) {

                toast.warning("No hay postulantes registrados");
                await this.setState({
                    statusTable: 1
                });
            } else {
                toast.info(`Se encontraron ${length} postulantes`);
            }

        }
        if (origen === 1) {
            toast.success(`Tabla actualizada`);
            if (length === 0) {

                toast.warning("No hay postulantes registrados");

                await this.setState({
                    statusTable: 1
                });
            } else {
                toast.info(`Se encontraron ${length} postulantes`);
            }

        }
    }

    searchPostulante = async (tipoBusqueda, referencia) => {
        let response = await postulanteModel.searchPostulante(tipoBusqueda, referencia);

        let { postulanteList } = response;
        let array = [];
        let { IdFranquicia } = cookies.get("user", {
            path: "/"
        });
        console.log(IdFranquicia);
        if (IdFranquicia == 0) {
            array = postulanteList
        } else {
            for (let i = 0; i < postulanteList.length; i++) {
                console.log(postulanteList[i]);
                if (IdFranquicia == postulanteList[i].IdFranquicia) {
                    array.push(postulanteList[i])
                }
            }
        }


        await this.setState({
            postulanteList: array,
            statusTable: array.length === 0 ? 1 : this.state.statusTable
        });
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <NavBar isMobileDevice={false} />
                <div
                    style={{
                        fontFamily: "monospace",
                        marginTop: "100px",
                        backgroundColor: colors.blanco,
                        marginRight: "20px",
                        marginLeft: "20px",
                        borderRadius: "8px",
                        marginBottom: "50px",
                    }}
                >
                    <Row
                        style={{
                            marginRight: "10px",
                            marginLeft: "10px",
                        }}
                    >
                        <Col>
                            <Row>
                                <Col
                                    style={{
                                        marginTop: "10px",
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Buscar postulante por
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                id="tipoBusqueda"
                                                name="tipoBusqueda"
                                                type="select"
                                                style={{
                                                    // borderColor: "transparent",
                                                    // backgroundColor: "transparent",
                                                    // borderBottom: `solid 0.5px ${colors.azul}`,
                                                    boxShadow: "none",
                                                    // fontSize: "18px",
                                                }}
                                                // value={}
                                                onChange={async (event) => {
                                                    let { value } = event.target;
                                                    await this.setState({
                                                        tipoBusqueda: value
                                                    });
                                                    await components.fucusElement("spesific");
                                                }}
                                            >
                                                <option value={1}>Nombre</option>
                                                <option value={2}>Genero</option>
                                                {/* <option value={3}>Franquicia</option> */}
                                                <option value={4}>Estado civil</option>
                                                <option value={5}>Nivel de estudio</option>
                                                <option value={6}>Puesto solicitado</option>
                                                <option value={7}>Unidad</option>
                                            </Input>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col
                                    style={{
                                        marginTop: "10px",
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Filtra búsqueda
                                        </Label>
                                        <InputGroup>
                                            {
                                                this.state.tipoBusqueda == 1 ?
                                                    <>
                                                        <Input
                                                            id="spesific"
                                                            name=""
                                                            type="text"
                                                            style={{
                                                                // borderColor: "transparent",
                                                                // backgroundColor: "transparent",
                                                                // borderBottom: `solid 0.5px ${colors.azul}`,
                                                                boxShadow: "none",
                                                                // fontSize: "18px",
                                                            }}
                                                            // value={}
                                                            onChange={(event) => {
                                                                this.setState({
                                                                    referencia: event.target.value,
                                                                });
                                                            }}
                                                            onKeyDown={(event) => {
                                                                if (event.key === "Enter") {
                                                                    this.searchPostulante(this.state.tipoBusqueda, this.state.referencia);
                                                                }
                                                            }}
                                                        />
                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.tipoBusqueda == 2 ?
                                                    <>
                                                        <Input
                                                            id="spesific"
                                                            name=""
                                                            type="select"
                                                            style={{
                                                                // borderColor: "transparent",
                                                                // backgroundColor: "transparent",
                                                                // borderBottom: `solid 0.5px ${colors.azul}`,
                                                                boxShadow: "none",
                                                                // fontSize: "18px",
                                                            }}
                                                            // value={}
                                                            onChange={(event) => {
                                                                let referencia = event.target.value;
                                                                this.searchPostulante(this.state.tipoBusqueda, referencia);
                                                            }}
                                                        >
                                                            <option value={0}>Elige  una opción</option>
                                                            <option value={"Hombre"}>Hombre</option>
                                                            <option value={"Mujer"}>Mujer</option>
                                                        </Input>
                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.tipoBusqueda == 3 ?
                                                    <>
                                                        <Input
                                                            id="spesific"
                                                            name=""
                                                            type="select"
                                                            style={{
                                                                // borderColor: "transparent",
                                                                // backgroundColor: "transparent",
                                                                // borderBottom: `solid 0.5px ${colors.azul}`,
                                                                boxShadow: "none",
                                                                // fontSize: "18px",
                                                            }}
                                                            // value={}
                                                            onChange={(event) => {
                                                                let referencia = event.target.value;
                                                                this.searchPostulante(this.state.tipoBusqueda, referencia);
                                                            }}
                                                        >
                                                            <option value={0}>Elige  una opción</option>
                                                            <option value={"Franquicia A"}>Franquicia A</option>
                                                            <option value={"Franquicia B"}>Franquicia B</option>
                                                            <option value={"Franquicia C"}>Franquicia C</option>
                                                            <option value={"Franquicia D"}>Franquicia D</option>
                                                        </Input>
                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.tipoBusqueda == 4 ?
                                                    <>
                                                        <Input
                                                            id="spesific"
                                                            name=""
                                                            type="select"
                                                            style={{
                                                                // borderColor: "transparent",
                                                                // backgroundColor: "transparent",
                                                                // borderBottom: `solid 0.5px ${colors.azul}`,
                                                                boxShadow: "none",
                                                                // fontSize: "18px",
                                                            }}
                                                            // value={}
                                                            onChange={(event) => {
                                                                let referencia = event.target.value;
                                                                this.searchPostulante(this.state.tipoBusqueda, referencia);
                                                            }}
                                                        >
                                                            <option value={0}>Elige  una opción</option>
                                                            {this.state.arrayMaritalStatuses.map((value) => {
                                                                return (
                                                                    <option value={value}>{value}</option>
                                                                );
                                                            })}
                                                        </Input>
                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.tipoBusqueda == 5 ?
                                                    <>
                                                        <Input
                                                            id="spesific"
                                                            name=""
                                                            type="select"
                                                            style={{
                                                                // borderColor: "transparent",
                                                                // backgroundColor: "transparent",
                                                                // borderBottom: `solid 0.5px ${colors.azul}`,
                                                                boxShadow: "none",
                                                                // fontSize: "18px",
                                                            }}
                                                            // value={}
                                                            onChange={(event) => {
                                                                let referencia = event.target.value;
                                                                this.searchPostulante(this.state.tipoBusqueda, referencia);
                                                            }}
                                                        >
                                                            <option value={0}>Elige  una opción</option>
                                                            {this.state.arrayStudies.map((value) => {
                                                                return (
                                                                    <option value={value}>{value}</option>
                                                                );
                                                            })}
                                                        </Input>
                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.tipoBusqueda == 6 ?
                                                    <>
                                                        <Input
                                                            id="spesific"
                                                            name=""
                                                            type="select"
                                                            style={{
                                                                // borderColor: "transparent",
                                                                // backgroundColor: "transparent",
                                                                // borderBottom: `solid 0.5px ${colors.azul}`,
                                                                boxShadow: "none",
                                                                // fontSize: "18px",
                                                            }}
                                                            // value={}
                                                            onChange={(event) => {
                                                                let referencia = event.target.value;
                                                                this.searchPostulante(this.state.tipoBusqueda, referencia);
                                                            }}
                                                        >
                                                            <option value={0}>Elige  una opción</option>
                                                            {this.state.arrayRequiredPosition.map((value) => {
                                                                return (
                                                                    <option value={value}>{value}</option>
                                                                );
                                                            })}
                                                        </Input>
                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.tipoBusqueda == 7 ?
                                                    <>
                                                        <Input
                                                            id="spesific"
                                                            name=""
                                                            type="text"
                                                            style={{
                                                                // borderColor: "transparent",
                                                                // backgroundColor: "transparent",
                                                                // borderBottom: `solid 0.5px ${colors.azul}`,
                                                                boxShadow: "none",
                                                                // fontSize: "18px",
                                                            }}
                                                            value={this.state.nombreUnidadAuxiliar}
                                                            onChange={(event) => {
                                                                let referencia = event.target.value;
                                                                // this.searchPostulante(this.state.tipoBusqueda, referencia);
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isOpenModalUnidad: true,
                                                                });
                                                            }}
                                                        />
                                                    </>
                                                    :
                                                    null
                                            }
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                textAlign: "right",
                            }}
                        >
                            <Button
                                title="Actualizar tabla"
                                style={{
                                    backgroundColor: colors.azul,
                                    height: "40px",
                                    width: "50px",
                                    marginTop: "30px"
                                }}
                                onClick={() => {
                                    this.getPostulanteList(1);
                                }}
                            >
                                {this.state.isLoadTable ?
                                    <Spinner
                                        style={{
                                            marginTop: "2px",
                                            height: "19px",
                                            width: "19px",
                                        }}
                                    />
                                    :
                                    <Cached />
                                }

                            </Button>
                        </Col>
                    </Row>
                </div>
                <div
                    style={{
                        fontFamily: "monospace",
                        marginTop: "50px",
                        backgroundColor: colors.blanco,
                        marginRight: "20px",
                        marginLeft: "20px",
                        borderRadius: "8px",
                        marginBottom: "50px"
                    }}
                >
                    <div
                        style={{
                            marginRight: "20px",
                            marginLeft: "20px"
                        }}
                    >
                        <Row
                            style={{
                                marginBottom: "10px"
                            }}
                        >
                            <Col>
                                <Label
                                    style={{
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                        fontSize: "22px"
                                    }}
                                >
                                    Lista de postulantes ({this.state.isLoadTable ? "..." : this.state.postulanteList.length})
                                </Label>

                            </Col>
                        </Row>
                        {
                            this.state.postulanteList.length === 0 ?
                                <center>
                                    {
                                        this.state.statusTable === 0 ?


                                            <lottie-player
                                                autoplay
                                                loop
                                                mode="normal"
                                                src="https://assets10.lottiefiles.com/packages/lf20_gbfwtkzw.json"

                                                style={{ width: "300px", height: "300px" }}
                                            ></lottie-player>
                                            :

                                            <Label>Tabla vacia</Label>
                                    }
                                </center>
                                :
                                <Table
                                    columns={this.state.columnList}
                                    data={this.state.postulanteList}
                                    noDataComponent={
                                        <label
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            tabla vacia
                                        </label>
                                    }
                                // pagination
                                />
                        }

                    </div>
                    <Row style={{ marginTop: "20px" }} ><Col></Col></Row>

                </div>
                <Modal

                    isOpen={this.state.isOpenModalApplicant}
                    // size="xl"
                    // fullscreen                
                    style={{
                        maxWidth: "1500px",
                        height: "750px"
                    }}
                >
                    <ModalHeader
                        toggle={() => {
                            this.setState({
                                isOpenModalApplicant: false
                            });
                        }}
                    >
                        Datos del postulante (Actualmente {this.state.dataApplicant.trabajaActualmente === 0 ? "no" : ""} trabaja)
                    </ModalHeader>

                    <ModalBody
                        id="modal"
                    >

                        <Row>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Nombre(s) y Apellidos
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.nombreCompleto}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Dirección
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.direccion}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Teléfono
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.telefonoCelular}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Correo
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.correo}
                                </Label>
                            </Col>
                        </Row>
                        <DropdownItem divider />
                        <Row>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Fecha de nacimiento ({postulanteModel.calcularEdad(this.state.dataApplicant.fechaNacimiento.substring(0, 10))})
                                </Label>
                                <br />
                                <Label
                                    title={DataTimeTool.castDate(this.state.dataApplicant.fechaNacimiento)}
                                    style={{
                                        cursor: "help"
                                    }}
                                >
                                    {DataTimeTool.dateFormatoSimple(this.state.dataApplicant.fechaNacimiento)}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Estado civil
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.estadoCivil}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Turno deseado
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.turnoDeseado}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Empleo
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.empleo}
                                </Label>
                            </Col>
                        </Row>
                        <DropdownItem divider />
                        <Row>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Estudios
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.estudios}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Disponibilidad
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.disponibilidad}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Puesto solicitado
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.puestoSolicitado}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Experiencia laboral
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.experienciaLaboral}
                                </Label>
                            </Col>
                        </Row>
                        <DropdownItem divider />
                        <Row>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Depende de el economicamente
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.depentdeDeAlguienEconomicamente === 0 ? "No" : "Si"}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Trabajo en un dominos antes
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.trabajoEnDominosAntes === 0 ? "No" : "Si"}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    El por qué deberíamos de contratarlo
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.porqueContratarlo}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    RFC (Opcional)
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.RFC}
                                </Label>
                            </Col>
                        </Row>
                        <DropdownItem divider />
                        <Row>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Unidad en la quei quiere trabajar
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.nombreUnidad} ({this.state.dataApplicant.UDN})
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Trabaja actualmente
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.trabajaActualmente === 1 ? "Si" : "No"}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Sexo
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.sexo}
                                </Label>
                            </Col>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Estado en el que vive
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.nombreEstado}
                                </Label>
                            </Col>
                        </Row>
                        <DropdownItem divider />
                        <Row>
                            {
                                this.state.dataApplicant.depentdeDeAlguienEconomicamente === 1 ?
                                    <Col
                                        md="3"
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            Quienes dependen del postulante
                                        </Label>
                                        <br />
                                        <Label>
                                            {this.state.dataApplicant.personaDeQuienDepende}
                                        </Label>
                                    </Col>
                                    :
                                    null
                            }
                            <Col
                                md="3"
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Municipio en el que vive
                                </Label>
                                <br />
                                <Label>
                                    {this.state.dataApplicant.nombreMunicipio}
                                </Label>
                            </Col>

                            <Col
                                md="3"
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px"
                                    }}
                                >
                                    Fecha y hora en la que se postulo
                                </Label>
                                <br />
                                <Label
                                    title={DataTimeTool.castDate(this.state.dataApplicant.fechaPostulacion) + " a las " + DataTimeTool.timeFormatoSimple(this.state.dataApplicant.horaPostulacion)}
                                >
                                    {DataTimeTool.dateFormatoSimple(this.state.dataApplicant.fechaPostulacion)} a las {DataTimeTool.timeFormatoSimple(this.state.dataApplicant.horaPostulacion)}
                                </Label>
                            </Col>

                        </Row>

                        {
                            this.state.dataApplicant.trabajoEnDominosAntes === 1 ?
                                <>
                                    <DropdownItem divider />
                                    <Row>
                                        <Col>
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "16px"
                                                }}
                                            >
                                                Datos de la unidad de dominos donde trabajo
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                Unidad
                                            </Label>
                                            <br />
                                            <Label>
                                                {this.state.dataApplicant.sucursalDominosAntes}
                                            </Label>
                                        </Col>
                                        <Col>
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                Fecah de salida
                                            </Label>
                                            <br />
                                            <Label
                                                title={DataTimeTool.castDate(this.state.dataApplicant.fechaSalidaDominosAntes)}
                                                style={{
                                                    cursor: "help"
                                                }}
                                            >
                                                {DataTimeTool.dateFormatoSimple(this.state.dataApplicant.fechaSalidaDominosAntes)}
                                            </Label>
                                        </Col>
                                        <Col>
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                Tiempo que trabajo en la unidad
                                            </Label>
                                            <br />
                                            <Label>
                                                {this.state.dataApplicant.tiempoTrabajandoDiminos}
                                            </Label>

                                        </Col>
                                    </Row>
                                </>
                                :
                                null
                        }

                    </ModalBody>

                    <ModalFooter>

                        <Button
                            style={{
                                width: "150px",
                                backgroundColor: colors.rojo
                            }}
                            onClick={async () => {
                                await this.deletePostulante(this.state.dataApplicant);

                            }}
                        >
                            <Delete />
                            Eliminar
                        </Button>
                        {/* <Button
                            style={{
                                width: "150px",
                                backgroundColor: colors.azul
                            }}
                            onClick={() => {
                                this.setState({
                                    isOpenModalApplicant: false,
                                });
                            }}
                        >
                            <Clear />
                            Cerrar
                        </Button> 
                          */}
                        <Button
                            style={{
                                width: "150px",
                                backgroundColor: colors.azul
                            }}
                            onClick={() => {
                                try {
                                    const doc = new jsPDF();

                                    //get table html
                                    const pdf = document.getElementById('templatePDF');
                                    //html to pdf format
                                    var html = htmlToPdfmake(pdf.innerHTML);

                                    const documentDefinition = { content: html };
                                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                                    pdfMake.createPdf(documentDefinition).open();
                                } catch (error) {

                                }

                            }}
                        >
                            <PictureAsPdf />
                            Generar PDF
                        </Button>

                    </ModalFooter>

                </Modal>

                <div
                    hidden="true"
                    id="templatePDF"
                >
                    <Row>
                        <Col>
                            <center>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "28px",
                                        width: "100%",
                                        textAlign: "center"
                                    }}
                                >
                                    DATOS DEL POSTULANTE
                                </Label>
                            </center>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Nombre(s) y Apellidos: {this.state.dataApplicant.nombreCompleto}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Sexo: {this.state.dataApplicant.sexo}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Edad: {postulanteModel.calcularEdad(this.state.dataApplicant.fechaNacimiento.substring(0, 10))}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Estado y municipio en el que vive: {this.state.dataApplicant.nombreEstado}, {this.state.dataApplicant.nombreMunicipio}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Unidad en la quei quiere trabajar: {this.state.dataApplicant.nombreUnidad} ({this.state.dataApplicant.UDN})
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Trabaja actualmente: {this.state.dataApplicant.trabajaActualmente === 0 ? "No" : "Si"}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Dirección: {this.state.dataApplicant.direccion}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Teléfono: {this.state.dataApplicant.telefonoCelular}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Correo: {this.state.dataApplicant.correo}
                            </Label>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Fecha de nacimiento: {DataTimeTool.dateFormatoSimple(this.state.dataApplicant.fechaNacimiento)}
                                {/* ({postulanteModel.calcularEdad(this.state.dataApplicant.fechaNacimiento.substring(0, 10))}) {", nacio el"} {DataTimeTool.castDate(this.state.dataApplicant.fechaNacimiento)} */}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Estado civil: {this.state.dataApplicant.estadoCivil}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Turno deseado:  {this.state.dataApplicant.turnoDeseado}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Empleo: {this.state.dataApplicant.empleo}
                            </Label>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Estudios: {this.state.dataApplicant.estudios}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Disponibilidad: {this.state.dataApplicant.disponibilidad}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Puesto solicitado: {this.state.dataApplicant.puestoSolicitado}
                            </Label>
                        </Col>
                        <br />
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                            >
                                Experiencia laboral:  {this.state.dataApplicant.experienciaLaboral}
                            </Label>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Depende de el economicamente: {this.state.dataApplicant.depentdeDeAlguienEconomicamente === 0 ? "No" : "Si"}
                            </label>
                        </Col>
                        {
                            this.state.dataApplicant.depentdeDeAlguienEconomicamente == 1 ?
                                <>
                                    <br />
                                    <Col>
                                        <label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            Quien depende del postulante: {this.state.dataApplicant.personaDeQuienDepende}
                                        </label>
                                    </Col>
                                </>
                                :
                                null
                        }
                        <br />
                        <Col>
                            <label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Trabajo en un dominos antes: {this.state.dataApplicant.trabajoEnDominosAntes === 0 ? "No" : "Si"}
                            </label>
                        </Col>
                        <br />
                        <Col>
                            <label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                El por qué deberíamos de contratarlo: {this.state.dataApplicant.porqueContratarlo}
                            </label>
                        </Col>
                        <br />
                        <Col>
                            <label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                RFC (Opcional): {this.state.dataApplicant.RFC}
                            </label>
                        </Col>
                        <br />
                        <Col>
                            <label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }}
                            >
                                Fecha y hora en la que se postulo: {DataTimeTool.dateFormatoSimple(this.state.dataApplicant.fechaPostulacion)} a las {DataTimeTool.timeFormatoSimple(this.state.dataApplicant.horaPostulacion)}
                            </label>
                        </Col>
                    </Row>
                </div>
                <Modal
                    isOpen={this.state.isOpenModalUnidad}
                    size="lg"
                    style={{
                        maxWidth: "90%"
                    }}
                >
                    <ModalHeader>
                        Escoge una unidad
                    </ModalHeader>
                    <ModalBody
                        style={{
                            overflowY: "scroll",
                            height: "400px",
                            border: "1px solid #ddd",
                        }}
                    >
                        <Row>
                            <Col>
                                Lista de Unidades ({this.state.unidadList.length})
                            </Col>
                        </Row>
                        <Row>
                        <Table
                            columns={this.state.columnTableUnidades}
                            data={this.state.unidadList}
                        />
                        </Row>
                       
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                fontWeight: "bold"
                            }}
                            onClick={() => {
                                this.setState({
                                    isOpenModalUnidad: false,
                                });
                            }}
                        >
                            Cancelar
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}


export default ApplicantList