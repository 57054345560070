const dateFormatoSimple = (date) => {

    if(date === "" ) return "";
    
    //date = new Date(date);
    let year = date.substring(0,4);
    let month = date.substring(5,7);
    let days = date.substring(8,10);



    date =  days+ "/" + month + "/" + year;
    return date;
}

const invertirDateFormatoSimple = (date) => {

    if(date === "" ) return "";
    
    //date = new Date(date);
    let year = date.substring(0,4);
    let month = date.substring(5,7);
    let days = date.substring(8,10);



    date = year + "/" + month + "/" +days ;
    return date;
}


const dateFormato = (date) => {

    if(date === "" ) return "";
    
    date = new Date(date)       
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let days = date.getDate() ;

    if (days <= 9) {
        days = "0" + days
    }
    if (month <= 9) {
        month = "0" + month
    }

    date =  year + "-" + month + "-" + days;
    return date;
}

const dateFormatoCompleto = (date) =>{

    if(date === "" ) return "Valor no acpetado";

    let fecha = new Date(parseInt(date.substring(0,4)), parseInt(date.substring(5,7))-1, parseInt(date.substring(8,10)));
    // fecha = sumarDias(fecha,1)
    const dias = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado'
      ];
    let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const numeroDia = fecha.getDay();
    const nombreDia = dias[numeroDia];
    return nombreDia+", "+fecha.toLocaleDateString("es-ES", options); //+ ': ' + (numeroDia>=1 && numeroDia <= 5 ? "Avil":"Inavil");
}

const sumarDias = (date, days) => {
    
    date = new Date(date)
    
    date.setDate(date.getDate()  + days);
    
    return date;
}

const castDate = (date) => {

    if(date === "" ) return "";

    
    date = date.substring(0,10);
    
    let fecha = new window.Date(date);
    const dias = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ];
    let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const numeroDia = fecha.getDay();
    const nombreDia = dias[numeroDia];
    return nombreDia+", "+fecha.toLocaleDateString("es-ES", options) //+ ': ' + (numeroDia>=1 && numeroDia <= 5 ? "Avil":"Inavil");
}

const timeFormatoSimple = (time) => {
    


    let meridiano = {
        1 : "AM",
        2 : "PM"
    }

    

    let tipoMeridiado = 1;
    let horas = parseInt(time.substring(0,2));


    if(horas >= 13){        
        tipoMeridiado = 2;
        horas = horas - 12
    }

    if(horas < 10){
        horas = "0"+horas

    }

    if(horas === 12){        
        tipoMeridiado = 2;        
    }

    let minutos = time.substring(3,5);
    let segundos = time.substring(6,8);
    
    return horas +':'+ minutos+':'+segundos+' '+meridiano[tipoMeridiado];
}



const DataTimeTool = {
    sumarDias,
    dateFormatoCompleto,
    dateFormato,
    dateFormatoSimple,
    castDate,
    invertirDateFormatoSimple,
    timeFormatoSimple
}


export default DataTimeTool;