import axios from 'axios';
import API from '../network/api.network';
import swal2 from 'sweetalert2';
import md5 from 'md5';
import colors from "../config/Colors.config";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const urlApi = API + "/user"

const mensajeError = (error) => {
    
    let menssage = error.config.url + ": " + error.message

    
    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.gris_1};">${menssage}</p>`
    });
}


const userLogin = async (user) => {
    
    user = {
        ...user,
        password: md5(user.password)
    }
    let response;
    let data = null;
    try {
        response = await axios({
            method: "POST",
            url: urlApi + "/login",
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
            data: {
                user
            }
        })
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data

}

const getUserCookies = async () => {
    let user = cookies.get("user", {
        path: "/"
    });
    
    if (!user) {
        document.body.innerHTML = "<body></body>"
        await swal2.fire({            icon: 'warning',
            title: 'Atencion',
            text: "Tienes que inisiar sesion para poder usar este sistema web",
            timer: "10000",
            confirmButtonColor: '#016390',
            confirmButtonText: 'Okey',
            allowEscapeKey: true,
            timerProgressBar: true
        }).then((value) => {
            if (value.isConfirmed) {
                window.location.href = "/";
            }

        });
        window.location.href = "/";
    }
}

const getUserLocal = () => {
    let user = cookies.get("user", {
        path: "/"
    });
    return user;
}

const getUSerList = async (textoBuscador) => {
    
    if(textoBuscador.length === 0){
        textoBuscador = "all";
    }
    let response;
    let data = null;
    let usuario =  await cookies.get("user",{ path: "/" });
        
    try {
        response = await axios({
            method: "GET",
            url: urlApi + `/list/${textoBuscador}/${usuario.IdUsuario}`,
            headers: {
                token : await cookies.get("token",{ path: "/" }),
                "Access-Control-Allow-Origin": "*"
            },
            
        })
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data
}

const userSave = async (user) => {
    // user = {
    //     ...user,
    //     password : user.password == undefined ? "" :md5(user.password),
    // }

    

    let response;
    let data = null;
    try {
        response = await axios({
            method: "POST",
            url: urlApi + `/save`,
            headers: {
                token : await cookies.get("token",{ path: "/" }),
                "Access-Control-Allow-Origin": "*"
            },
            data:{
                user
            }
            
        })
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data
}

const userDelete = async (IdUsuario) => {

    let response;
    let data = null;
    try {
        response = await axios({
            method: "DELETE",
            url: urlApi + `/delete/${IdUsuario}`,
            headers: {
                token : await cookies.get("token",{ path: "/" }),
                "Access-Control-Allow-Origin": "*"
            },            
        })
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data
}

const userById = async (IdUsuario) => {

    let response;
    let data = null;
    try {
        response = await axios({
            method: "GET",
            url: urlApi + `/user-ById/${IdUsuario}`,
            headers: {
                token : await cookies.get("token",{ path: "/" }),
                "Access-Control-Allow-Origin": "*"
            },            
        })
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data
}

const susuario_check_primera_interaccion = async (username) => {

    let response;
    let data = null;
    try {
        response = await axios({
            method: "POST",
            url: urlApi + `/susuario_check_primera_interaccion`,
            headers: {
                // token : await cookies.get("token",{ path: "/" }),
                "Access-Control-Allow-Origin": "*"
            },    
            data:{
                username
            }        
        })
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data
}

const usuario_asignar_password = async (password) => {

    let response;
    let data = null;
    try {
        response = await axios({
            method: "POST",
            url: urlApi + `/usuario_asignar_password`,
            headers: {
                // token : await cookies.get("token",{ path: "/" }),
                "Access-Control-Allow-Origin": "*"
            },    
            data:{
                password : md5(password),
                IdUsuario : await cookies.get("user",{ path: "/" }).IdUsuario, 
            }        
        })
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data
}


const userModel = {
    userLogin,
    getUserCookies,
    getUserLocal,
    getUSerList,
    userSave,
    userDelete,
    userById,
    susuario_check_primera_interaccion,
    usuario_asignar_password,
}

export default userModel;