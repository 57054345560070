import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

// Vistas de escritorio
import ApplicantFormDesktop from "./pages/desktop/ApplicantForm";
import ApplicantListDesktop from "./pages/desktop/ApplicantList";
import UserListDesktop from "./pages/desktop/UserList";
import LoginDesktop from "./pages/desktop/Login";
import CostCenter from "./pages/desktop/CostCenter";
import NewUser from "./pages/desktop/NewUser";

// Vistas de moviles
import ApplicantFormMovil from "./pages/movil/ApplicantForm";
import ApplicantListMovil from "./pages/movil/ApplicantList";
import UserListMovil from "./pages/movil/UserList";
import LoginMovil from "./pages/movil/Login";

import NotFound404 from "./pages/templates/NotFound404";

function App() {

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isDesktopDevice = !regexp.test(details); 
  let isMobileDevice = !isDesktopDevice;
  return (
    <Router>
        <Routes>          
        <Route exact path="/" element={isDesktopDevice ? <ApplicantFormDesktop/> : <ApplicantFormMovil/>}/>   
          <Route exact path="/:franquicia" element={isDesktopDevice ? <ApplicantFormDesktop/> : <ApplicantFormMovil/>}/>   
          <Route exact path="/list" element={isDesktopDevice ? <ApplicantListDesktop/> : <ApplicantListMovil/>}/>   
          <Route exact path="/cost-center" element={<CostCenter/>}/>   
          <Route exact path="/login" element={isDesktopDevice ? <LoginDesktop/> : <LoginMovil/>}/>   
          <Route exact path="/user-list" element={isDesktopDevice ? <UserListDesktop/> : <UserListMovil/>}/>   
          <Route exact path="/new-user/:username" element={<NewUser/>}/>   
          <Route path='*' element={<NotFound404/>}/>
        </Routes>
      </Router>
  );
}

export default App;
